import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import './BoletosCliente.scss'
import './../../main/ultil.scss'
import CircularProgress from '@mui/material/CircularProgress';
import { Grid, Button, TextField } from '@mui/material'
import axios from 'axios'
import moment from 'moment';
import ConfirmarCPFCNPJ from './../../components/modals/ConfirmarCPFCNPJ'
import ModalExibeBoleto from './../../components/modals/ExibeBoleto'
import ModalViewContaCliente from './../../components/modals/ViewContaCliente'
import ModalConfirmacao from './../../components/modals/Confirmacao'
import ModalAnteciparParcelas from './../../components/modals/AnteciparParcelas'
import ModalQuitacao from './../../components/modals/Quitacao'
import ModalAlteracaoDadosCadastro from './../../components/modals/AlteracaoDadosCadastro'
import ModalVisualizarDocumento from './../../components/modals/VisualizarDocumento'
import real from '../../services/real'
import Alert from './../../components/templates/Alert'
import VisibilityIcon from '@mui/icons-material/Visibility';
import PrintIcon from '@mui/icons-material/Print';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import icone_nfse from './logo_nfse.png'
import icone_nfse_disabled from './logo_nfse_disabled.png'
import { saveAs } from 'file-saver';
import decrypt from '../../services/decrypt'
import ModalAjuda from './../../components/modals/Ajuda'
import { styled, keyframes } from "@mui/system";
import {filesize} from "filesize";
import getEndereco from './../../services/api/getEndereco'

const cpf = require('node-cpf-cnpj');

function renderClassFinanceiro(param) {
  if (param === 'Atrasado') {
    return  '#FFB6C1'
  } else if (param === 'A Pagar') {
    return  '#FFF'
  } else if (param === 'Pago') {
    return '#98FB98'
  }
}

const initialState = {
  cabecalhoTabela: [
    // { id: 'descricao', numeric: false, disablePadding: true, label: 'Descrição' },
    { id: 'data_vencimento', numeric: false, disablePadding: true, label: 'Data Vencimento', orderBy: 'data_vencimentoOrder' },
    // { id: 'tipo_conta', numeric: false, disablePadding: true, label: 'Tipo Conta' },
    // { id: 'forma_pagto', numeric: false, disablePadding: true, label: 'Forma Pagto.' },
    // { id: 'valor', numeric: false, disablePadding: true, label: 'Valor' },
    // { id: 'multajuros', numeric: false, disablePadding: true, label: 'Multa / Juros / Desc.' },
    { id: 'valor_total_form', numeric: true, disablePadding: true, label: 'Valor Total' },
    // { id: 'qtd_itens', numeric: true, disablePadding: true, label: 'Qtd. Itens' },
    { id: 'status', numeric: false, disablePadding: true, label: 'Status' }
  ],
  acoesTabela: ['openConta', 'imprimirBoletoCliente'],
  contas: {
    list: []
  },
  contas_abertas: {
    list: []
  },
  openModalConfirmar: false,
  login: {
    cpf_cnpj: ''
  },
  pessoa: {
    tipo: 'FISICA',
    fisica: {
      nome: ''
    },
    unidade_pertence: {
      descricao: '',
      hab_alt_cad_externo: false
    }
  },
  mostrarBoletos: false,
  loading: false,
  openModalExibeBoleto: false,
  boletoSelecionado: {
    tipo_transaction: 'BOLETO',
    bank_slip: {
      url_slip_pdf: ''
    }
  },
  alerta: {
    open: false,
    severity: 'error',
    message: ''
  },
  filtro: {
    data_inicial: "",
    data_final: "",
    situacao: "A VENCER E VENCIDO",
    data_por: "VENCIMENTO",
  },
  parcela: {
    baixado: "",
    datavencimento: "",
    valorparcela: '0,00',
    documento: "",
    num: "",
    motivo_cancelamento: "",
    parcela_editada: false,
    multajuros: '0,00',
    formas_pgto: [
      {
        id: '',
        formapagto_id: '',
        valor_pagamento: real(0),
        formapagto_descricao: '',
        formapagto_hab_caixa: false,
        contfin_id: '',
        contfin_descricao: '',
        contfin_tipo_conta: '',
        index: 0
      }
    ]
  },
  openModalViewContaCliente: false,
  dataSelecionada: {
    parcelas: []
  },
  row_selecionado: null,
  modal: {
    mensagem: '',
  },
  modalOpen: false,
  tabela_mes: [
    {
      numero: '01',
      mes: 'Janeiro'
    },
    {
      numero: '02',
      mes: 'Fevereiro'
    },
    {
      numero: '03',
      mes: 'Março'
    },
    {
      numero: '04',
      mes: 'Abril'
    },
    {
      numero: '05',
      mes: 'Maio'
    },
    {
      numero: '06',
      mes: 'Junho'
    },
    {
      numero: '07',
      mes: 'Julho'
    },
    {
      numero: '08',
      mes: 'Agosto'
    },
    {
      numero: '09',
      mes: 'Setembro'
    },
    {
      numero: '10',
      mes: 'Outubro'
    },
    {
      numero: '11',
      mes: 'Novembro'
    },
    {
      numero: '12',
      mes: 'Dezembro'
    }
  ],
  openModalAjuda: false,
  markdown: '',
  openModalAnteciparParcelas: false,
  parcelas_antecipacao: [],
  openModalQuitacao: false,
  selecionarTodos: false,
  disableFiltro: false,
  possui_antecipacao: false,
  openModalAlteracaoDadosCadastro: false,
  cliente: {
    tipoPessoa: 'FISICA',
    fisica: {
      cpf: '',
      nome: '',
      sobrenome: '',
      telefone: '55',
      data_nascimento: '',
      email: '',
      dadosComplementar: {
        sexo: '',
        num_calcado: '',
        receber_novidades: false,
        quantidade_filhos: '',
        profissao: '',
        data_casamento: '',
        rg: '',
        rg_orgao_emissor: '',
        rg_uf: '',
        rg_data_emissao: '',
        como_conheceu_id: '',
        estado_civil: '',
        colegio: '',
        nome_pai: '',
        nome_mae: '',
        cnh_cliente: '',
        nome_conjugue: '',
        cpf_conjugue: '',
        rg_conjugue: '',
        rg_orgao_emissor_conjugue: '',
        rg_data_emissao_conjugue: '',
        data_nascimento_conjugue: '',
        email_conjugue: '',
        telefone_conjugue: '55',
      }
    },
    juridica: {
      cnpj: '',
      razao_social: '',
      nome_fantasia: '',
      data_fundacao: '',
      telefone: '',
      email: '',
    },
    endereco:{
      cep_id: '',
      cep: '',
      logradouro: '',
      numero: '',
      complemento: '',
      bairro: '',
      cidade: '',
      estado: ''
    },
    files: {
      doc_rg_frente: [],
      doc_rg_verso: [],
      doc_cnh_frente: [],
      doc_cnh_verso: [],
      doc_comprovante_renda: [],
      doc_comprovante_endereco: [],
    }
  },
  metodos_divugacoes:{
    list: []
  },
  dadosComplementaresPF: {
    list: []
  },
  openModalVisualizarDocumento: false,
  documento: {
    nome: '',
    url: '',
    formato: ''
  },
  loadingSalvar: false
}

// Definindo a animação de pulsar
const pulseAnimation = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.2); }
  100% { transform: scale(1); }
`;

// Aplicando a animação ao ícone
const PulsingIcon = styled(PrintIcon)`
  animation: ${pulseAnimation} 1s infinite;
`;

const BoletosCliente = () => {
  const [state, setState] = useState({ ...initialState });
  const { pessoaId } = useParams();

  const getToken = () => {
    const USER_TOKEN = localStorage.getItem('token_cliente')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  const confirmar = async () => {
    const { login } = state

    try {
      const dados = {
        cpf_cnpj: login.cpf_cnpj,
        pessoa_id: parseInt(pessoaId)
      }

      const { data } = await axios.post(`${window._env_.REACT_APP_API_URL}/cliente/login`, dados)

      localStorage.setItem('token_cliente', data.token)

      buscarDadosPessoa()

      setState(prevState => ({...prevState,
        mostrarBoletos: true,
      }))

    } catch (error) {
      console.log(error)
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      }))
    }

  }

  const buscarDadosPessoa = async () => {
    try {
      const { data } = await axios.get(`${window._env_.REACT_APP_API_URL}/pessoaExterno/${parseInt(pessoaId)}`, getToken())
      
      let pessoa = JSON.parse(decrypt(data))

      console.log(pessoa)

      localStorage.setItem('cliente', JSON.stringify(pessoa))

      let {filtro} = state

      if(pessoa.unidade_id === 48 || pessoa.unidade_id === 66){
        filtro.data_inicial = moment().format('YYYY-MM')+'-01'
        filtro.data_final = moment().format('YYYY-MM-DD')
        setState(prevState => ({
          ...prevState,
          disableFiltro: true
        }))
      }else{
        filtro.data_inicial = moment().format('YYYY-MM')+'-01'
        filtro.data_final = moment(filtro.data_inicial).add(1, 'month').subtract(1, 'day').format('YYYY-MM-DD')
      }
  
      filtrarContas(state.filtro)

      setState(prevState => ({...prevState,
        pessoa,
        filtro
      }))
    } catch (error) {
      console.log(error)
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      }))
    }
  }

  const filtrarContas = async (filtro) => {
    setState(prevState => ({...prevState, loading: true }))

    try {
      const { data } = await axios.post(`${window._env_.REACT_APP_API_URL}/boleto/list/${parseInt(pessoaId)}`, filtro, getToken())

      let resp = JSON.parse(decrypt(data))
    
      setState(prevState => ({...prevState,
        contas: {
          list: resp.list.map((value, key) => {
            return {
              index: key,
              descricao: value.descricao,
              data_vencimento: moment(value.data_vencimento).format('DD/MM/YYYY'),
              data_vencimento_sem_form: moment(value.data_vencimento).format('YYYY-MM-DD'),
              valor: 'R$ ' + real(value.valor_total),
              multajuros: 'R$ ' + real(value.multajuros),
              valor_total:value.valor_total + value.multajuros,
              valor_total_form: 'R$ ' + real(value.valor_total + value.multajuros),
              qtd_itens: value.parcelas.length,
              responsavel: value.responsavel,
              tipo_conta: value.tipo_conta === 'RECEITA' ? 'A Pagar' : 'A Receber',
              forma_pgto_slug: value.forma_pgto_slug,
              forma_pagto: value.forma_pagto,
              status: value.status,
              boleto_transaction_id: value.boleto_transaction_id,
              boleto_link: value.boleto_link,
              tipo_transaction: value.tipo_transaction,
              boleto_digitable_line: value.boleto_digitable_line,
              parcelas: value.parcelas,
              nfse_ids: value.nfse_ids,
              parceiro_bancario_id: value.parceiro_bancario_id,
              parcela_antecipacao: value.parcela_antecipacao
            }
          })
        },
        possui_antecipacao: resp.possui_antecipacao,
        openModalConfirmar: false,
        mostrarBoletos: true,
        loading: false
      }))
    } catch (error) {
      console.log(error)
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      }))
    }
  }

  const updateField = (event) => {
    const { login } = state
    login[event.target.name] = event.target.value
    setState(prevState => ({...prevState, login }))
  }

  const imprimirBoleto = async (row) => {
    if(moment(row.data_vencimento_sem_form).format('MM/YYYY') !== moment().format('MM/YYYY')){
      let {tabela_mes} = state

      setState(prevState => ({...prevState,
        row_selecionado: row,
        modal: {
          mensagem: `Essa conta é referente ao mês de ${tabela_mes.filter(param => param.numero === moment(row.data_vencimento_sem_form).format('MM'))[0].mes} do ano de ${moment(row.data_vencimento_sem_form).format('YYYY')}, deseja continuar com o pagamento?`,
        },
        modalOpen: true,
      }))

    }else{
      await continuaImprimirBoletoPix(true, row)
    }    
  }

  const continuaImprimirBoletoPix = async (value, row) => {
    if(value){
      handleClose()
      let {contas} = state
  
      if(row.forma_pgto_slug === 'pix'){
  
        try {
          setState(prevState => ({...prevState,
            alerta: {
              open: true,
              severity: 'info',
              message: 'Carregando PIX'
            }
          }))
  
          const { data } = await axios.post(`${window._env_.REACT_APP_API_URL}/pix/atualiza/${row.parcelas[0].id}`,{}, getToken())
    
          contas.list[row.index].boleto_digitable_line = data.pix_code.emv
          contas.list[row.index].boleto_transaction_id = data.transaction_id
          contas.list[row.index].boleto_link = data.pix_code.qrcode_image_url
          contas.list[row.index].tipo_transaction = 'PIX'
  
          setState(prevState => ({...prevState,
            boletoSelecionado:{
              tipo_transaction: 'PIX',
              parceiro_bancario_id: row.parceiro_bancario_id,
              bank_slip:{
                url_slip_pdf: data.pix_code.qrcode_image_url,
                boleto_digitable_line: data.pix_code.emv
              },
              expiracao_pix: data.expiracao_pix
            },
            contas,
            alerta: {
              open: false,
              severity: 'info',
              message: ''
            },
            openModalExibeBoleto: true
          }))
          
        } catch (error) {
          console.log(error)
          setState(prevState => ({...prevState,
            alerta: {
              open: true,
              severity: 'error',
              message: error.response ? (error.response.data.message === 'payer_cpf_cnpj invalido' ? 'CPF/CNPJ do responsável inválido' : error.response.data.message) : 'Erro Interno'
            }
          }))
        }
      }

      if(row.forma_pgto_slug === 'boleto'){
        if(row.boleto_transaction_id){
          setState(prevState => ({...prevState,
            boletoSelecionado:{
              tipo_transaction: row.tipo_transaction,
              bank_slip:{
                url_slip_pdf: row.boleto_link,
                boleto_digitable_line: row.boleto_digitable_line
              }
            },
            openModalExibeBoleto: true
          }))
        }else{
          try {
            setState(prevState => ({...prevState,
              alerta: {
                open: true,
                severity: 'info',
                message: 'Carregando Boleto'
              }
            }))
    
            const { data } = await axios.post(`${window._env_.REACT_APP_API_URL}/boleto/atualiza/${row.parcelas[0].id}`,{}, getToken())
    
            contas.list[row.index].boleto_transaction_id = data.transaction_id
            contas.list[row.index].boleto_digitable_line = data.bank_slip.digitable_line
            contas.list[row.index].boleto_link = data.bank_slip.url_slip_pdf
            contas.list[row.index].tipo_transaction = 'BOLETO'
    
            setState(prevState => ({...prevState,
              boletoSelecionado:{
                tipo_transaction: 'BOLETO',
                bank_slip:{
                  url_slip_pdf: data.bank_slip.url_slip_pdf,
                  boleto_digitable_line: data.bank_slip.digitable_line
                }
              },
              contas,
              alerta: {
                open: false,
                severity: 'info',
                message: ''
              },
              openModalExibeBoleto: true
            }))
          } catch (error) {
            console.log(error)
            setState(prevState => ({...prevState,
              alerta: {
                open: true,
                severity: 'error',
                message: error.response ? (error.response.data.message === 'payer_cpf_cnpj invalido' ? 'CPF/CNPJ do responsável inválido' : error.response.data.message) : 'Erro Interno'
              }
            }))
          }
        }
      }
    }else{
      handleClose()
    }
  }

  const copyLink = (link) => {
    navigator.clipboard.writeText(link);
  
    setState(prevState => ({...prevState,
      alerta: {
        open: true,
        severity: 'success',
        message: 'Copiado!'
      }
    }))
    return
    
  }

  const handleModalExibeBoleto = () => {
    setState(prevState => ({...prevState, openModalExibeBoleto: false }))
  }

  const handleCloseAlerta = () => {
    setState(prevState => ({...prevState,
      alerta: {
        open: false,
        autoHideDuration: 5000,
        severity: state.alerta.severity,
        message: ''
      }
    }))
  }

  const updateFiltro = (event) => {
    const { filtro } = state

    filtro[event.target.name] = event.target.value

    setState(prevState => ({...prevState,
      filtro
    }))

  }

  const viewRow = async (row) => {
    setState(prevState => ({...prevState,
      dataSelecionada: row,
      openModalViewContaCliente: true
    }))
  }

  const handleModalViewContaCliente = () => {
    setState(prevState => ({...prevState,
      openModalViewContaCliente: false
    }))
  }

  const viewNFSe = async (row) => {
    setState(prevState => ({...prevState,
      alerta: {
        open: true,
        severity: 'info',
        message: 'Carregando NFSe'
      }
    }))

    for (const nfse of row.nfse_ids) {
      try {
    
        const { data } = await axios.post(`${window._env_.REACT_APP_API_URL}/nfse/imprimir`, {id: nfse.id}, getToken())
        
        const dadosImprimir = {
          path: data
        }
  
        const resp = await axios.post(`${window._env_.REACT_APP_API_URL}/imprimirPDF`, dadosImprimir, { responseType: 'blob' })
  
        const pdfBlob = new Blob([resp.data], { type: 'application/pdf' });
  
        setState(prevState => ({...prevState,
          loadingImprimir: false,
          alerta: {
            open: false,
            severity: 'info',
            message: ''
          }
        }))

        saveAs(pdfBlob, `NFSe-${nfse.numero}.pdf`)
      } catch (error) {
        console.log(error)
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'error',
            message: error.response ? error.response.data.message : 'Erro Interno'
          },
          loadingImprimir: false
        }))
      }
    }
  }

  const handleClose = () => {
    setState(prevState => ({...prevState,
      modal: {
        mensagem: ``,
      },
      modalOpen: false
    }))
  }

  const openQuitacao = async () => {

    try {
      const { data } = await axios.get(`${window._env_.REACT_APP_API_URL}/parcelaConta/listAbertasCliente/${parseInt(pessoaId)}`, getToken())

      let resp = JSON.parse(decrypt(data))
     
      let allStatus = [
        {
          de: 'A VENCER',
          para: 'A Pagar'
        },
        {
          de: 'BAIXADO',
          para: 'Pago'
        },
        {
          de: 'VENCIDO',
          para: 'Atrasado'
        }
      ]

      setState(prevState => ({...prevState,
        contas_abertas: {
          list: resp.map((value, key) => {
            return {
              parcela_id: value.id,
              descricao: value.conta.descricao,
              data_vencimento: moment(value.datavencimento_orig).format('DD/MM/YYYY'),
              valor_total:value.valorparcela + value.multajuros,
              status: allStatus.filter(param => param.de === value.situacao)[0].para,
              forma_pgto_slug: value.valores_pago[0].forma_pagto.slug
            }
          })
        },
        openModalQuitacao: true
      }))
    } catch (error) {
      console.log(error)
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      }))
    }
  }

  const handleCloseModalQuitacao = () => {
    setState(prevState => ({...prevState,
      openModalQuitacao: false
    }))
  }

  const handleCloseModalAnteciparParcelas = () => {
    setState(prevState => ({...prevState,
      openModalAnteciparParcelas: false
    }))
  }

  const selecionarParcelaAntecipar = (parcela, key) => {
    let {parcelas_antecipacao} = state

    let index = parcelas_antecipacao.findIndex(param => param.id === parcela.parcela_id)

    if(index === -1){
      if(key === parcelas_antecipacao.length){
        parcelas_antecipacao.push({
          id: parcela.parcela_id,
          valor: parcela.valor_total
        })
      }
    }else{
      if(key + 1 === parcelas_antecipacao.length){
        parcelas_antecipacao.splice(index, 1)
      }else{
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'warning',
            message: 'Não é possível desmarcar essa parcela, apenas a ultima parcela selecionada.'
          }
        }))
      }

    }

    setState((prevState) => ({
      ...prevState,
      parcelas_antecipacao
    }))
  }

  const openSelecionarParcelas = () => {
    setState(prevState => ({...prevState,
      openModalAnteciparParcelas: true
    }))
  }

  const quitarParcelas = async () => {
    try {
      let {contas_abertas} = state

      let dados = {
        parcelas: contas_abertas.list.map(value => {return value.parcela_id})
      }

      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'info',
          message: 'Gerando Antecipação...'
        }
      }))

      await axios.post(`${window._env_.REACT_APP_API_URL}/parcelaConta/quitarParcelas`, dados, getToken())
      
      await filtrarContas(state.filtro)
      // await continuaImprimirBoletoPix(true, {index: 0, forma_pgto_slug: 'pix', parcelas: contas_abertas.list.map(value => {return {id: value.parcela_id}})})

      handleCloseModalQuitacao()
      setState(prevState => ({...prevState,
        alerta: {
          open: false,
          severity: 'info',
          message: ''
        }
      }))

    } catch (error) {
      console.log(error)
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      }))
    }
  }

  const selecionarTodos = () => {
    let {contas_abertas} = state

    let parcelas_antecipacao = []

    for (const conta of contas_abertas.list) {
      parcelas_antecipacao.push({
        id: conta.parcela_id,
        valor: conta.valor_total
      })
    }

    setState((prevState) => ({
      ...prevState,
      parcelas_antecipacao,
      selecionarTodos: true
    }))

  }

  const desmarcarTodos = () => {
    setState((prevState) => ({
      ...prevState,
      parcelas_antecipacao: [],
      selecionarTodos: false
    }))

  }

  const confirmarAntecipacao = async () => {
    try {
      let {parcelas_antecipacao} = state

      let dados = {
        parcelas: parcelas_antecipacao.map(value => {return value.id})
      }

      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'info',
          message: 'Gerando Antecipação...'
        }
      }))

      await axios.post(`${window._env_.REACT_APP_API_URL}/parcelaConta/quitarParcelas`, dados, getToken())
      
      await filtrarContas(state.filtro)
      // await continuaImprimirBoletoPix(true, {index: 0, forma_pgto_slug: 'pix', parcelas: contas_abertas.list.map(value => {return {id: value.parcela_id}})})

      handleCloseModalQuitacao()
      handleCloseModalAnteciparParcelas()

      setState(prevState => ({...prevState,
        alerta: {
          open: false,
          severity: 'info',
          message: ''
        }
      }))

    } catch (error) {
      console.log(error)
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      }))
    }
  }

  const cancelarAntecipacao = async () => {
    try {
      let {contas} = state

      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'info',
          message: 'Cancelando Antecipações'
        }
      }))

      for (const value of contas.list) {
        if(value.parcela_antecipacao){
          await axios.put(`${window._env_.REACT_APP_API_URL}/parcelaConta/desfazerAntecipacao/${value.parcela_antecipacao.antecipacao.id}`, {}, getToken())
        }
      }

      await filtrarContas(state.filtro)

      setState(prevState => ({...prevState,
        alerta: {
          open: false,
          severity: 'info',
          message: ''
        }
      }))

    } catch (error) {
      console.log(error)
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      }))
    }
  }

  const atualizarDadosCadastro = async () => {
    const { pessoa } = state

    let endereco_principal = pessoa.enderecos.filter(param => param.principal)[0]

    let cliente = {
      pessoa_id: pessoa.id,
      tipoPessoa: pessoa.tipo,
      endereco:{
        id: endereco_principal.id,
        cep_id: endereco_principal.cep_id,
        cep: endereco_principal.num_cep,
        logradouro: endereco_principal.logradouro,
        numero: endereco_principal.numero,
        complemento: endereco_principal.complemento,
        bairro: endereco_principal.bairro,
        cidade: endereco_principal.cidade,
        estado: endereco_principal.estado
      }
    }

    let files = {
      doc_rg_frente: [],
      doc_rg_verso: [],
      doc_cnh_frente: [],
      doc_cnh_verso: [],
      doc_comprovante_renda: [],
      doc_comprovante_endereco: [],
    }  

    let telefone_principal = pessoa.telefones.filter(param => param.principal)[0]
    let email_principal = pessoa.emails.filter(param => param.principal)[0]

    if(pessoa.tipo === "FISICA"){
      cliente.fisica = {
        id: pessoa.fisica.id,
        cpf: pessoa.fisica.cpf,
        nome: pessoa.fisica.nome,
        sobrenome: pessoa.fisica.sobrenome,
        telefone: telefone_principal ? telefone_principal.numero : '55',
        data_nascimento: pessoa.fisica.datanascimento,
        email: email_principal ? email_principal.email : '',
        dadosComplementar: pessoa.fisica.dados_complementares
      }

      if(pessoa.fisica.dados_complementares){
        for (const key in files) {
                        
          let file = pessoa.fisica.dados_complementares[`${key}_obj`]
  
          if(file){
            files[key] = [{
              id: file.id,
              name: file.nome,
              preview: file.caminho,
              uploaded: true,
              url: file.caminho,
              formato: file.formato
            }]
          }
        }
      }
  
      cliente.files = files

    }else{
      cliente.juridica = {
        id: pessoa.juridica.id,
        cnpj: pessoa.juridica.cnpj,
        razao_social: pessoa.juridica.razaosocial,
        nome_fantasia: pessoa.juridica.nomefantasia,
        data_fundacao: pessoa.juridica.datafundacao,
        telefone: telefone_principal ? telefone_principal.numero : '55',
        email: email_principal ? email_principal.email : ''
      }
    }

    await consultaMetodosDivugacao(pessoa.unidade_id)
    await getDadoComplementarUnidade(pessoa.unidade_id)

    setState(prevState => ({...prevState,
      openModalAlteracaoDadosCadastro: true,
      cliente
    }))
  }

  const handleModalVisualizarDocumento = () => {
    setState((prevState) => ({
      ...prevState,
      openModalVisualizarDocumento: false,
      documento: {
        nome: '',
        url: ''
      }
    }))
  }

  const updateFieldTipo = (event) => {
    let {cliente} = state

    let name = event.target.name
    let value = event.target.value

    cliente[name] = value

    setState((prevState) => ({
      ...prevState,
      cliente
    }))

  }

  const updateFieldFisica = (event) => {
    let {cliente} = state

    let name = event.target.name
    let value = event.target.value

    cliente.fisica[name] = value

    setState((prevState) => ({
      ...prevState,
      cliente
    }))

  }

  const handleCloseModalAlteracaoDadosCadastro = () => {
    setState(prevState => ({...prevState,
      openModalAlteracaoDadosCadastro: false
    }))
  }

  const consultaMetodosDivugacao = async (unidade_id) => {
    try {
      const { data } = await axios.get(`${window._env_.REACT_APP_API_URL}/metodoDivugacaoExterno/${unidade_id}`)
      
      setState(prevState => ({...prevState,
        metodos_divugacoes:{
          list: JSON.parse(decrypt(data))
        }
      }))

    } catch (error) {
      console.log(error)
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      }))
    }
  }

  const updateFieldJuridica = (event) => {
    let {cliente} = state

    let name = event.target.name
    let value = event.target.value

    cliente.juridica[name] = value

    setState((prevState) => ({
      ...prevState,
      cliente
    }))

  }

  const getDadoComplementarUnidade = async (unidade_id) =>{
    try {
      const { data } = await axios.get(`${window._env_.REACT_APP_API_URL}/dadosComplementaresPFExterno/list/${unidade_id}`)

      let dadosComplementaresPF = JSON.parse(decrypt(data))

      setState(prevState => ({...prevState,
        dadosComplementaresPF: {
          list: dadosComplementaresPF
        }
      }))
    } catch (error) {
      console.log(error)
      // setState(prevState => ({...prevState,
      //   alerta: {
      //     open: true,
      //     severity: 'error',
      //     message: error.response ? error.response.data.message : 'Erro Interno'
      //   }
      // }))
      return false
    }
  }

  const updateFieldDadosComplementar = (event) => {
    let {cliente} = state

    let name = event.target.name
    let value = event.target.value

    cliente.fisica.dadosComplementar[name] = value

    setState((prevState) => ({
      ...prevState,
      cliente
    }))
  }

  const validarCpfCnpjConjugue = async (event) => {

    let cpf_cnpj = event.target.value
    const cliente = state.cliente

    cpf_cnpj = cpf.format(cpf_cnpj)
  
    if (!cpf.isValid(cpf_cnpj)) {
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          message: 'CPF Invalido! Por favor corrija o campo CPF do Cônjugue'
        }
      }))
      cliente.fisica.dadosComplementar.cpf_conjugue = ''
    }else{
      cliente.fisica.dadosComplementar.cpf_conjugue = cpf.format(cpf_cnpj)
    }

    setState(prevState => ({...prevState,
      cliente
    }))
  }

  const handleUpload = (files, name) => {
    let {cliente} = state

    const uploadedFiles = files.map((file, key) => ({
      file,
      id: '',
      name: file.name,
      readableSize: filesize(file.size),
      preview: URL.createObjectURL(file),
      progress: 0,
      titulo: "",
      link: "",
      uploaded: false,
      error: false,
      url: null,
      formato: file.type
    }))

    cliente.files[name] = uploadedFiles

    console.log(cliente.files)

    setState(prevState => ({...prevState,
      cliente
    }))
  }

  const updateFieldEndereco = (event) => {
    let {cliente} = state

    let name = event.target.name
    let value = event.target.value

    cliente.endereco[name] = value

    setState((prevState) => ({
      ...prevState,
      cliente
    }))
  }

  const buscaEndereco = async(event) => {
    let {cliente} = state

    if(event.target.value !== ''){
      try {
        cliente.endereco = {
          cep_id: '',
          cep: '',
          logradouro: 'Buscando Endereço...',
          complemento: '',
          numero: '',
          bairro: 'Buscando Endereço...',
          cidade: 'Buscando Endereço...',
          estado: 'Buscando Endereço...',
        }
  
        setState((prevState) => ({
          ...prevState,
          cliente
        }))
  
        const resp = await getEndereco(event.target.value)
  
        cliente.endereco = {
          cep_id: resp.id,
          cep: resp.cep,
          logradouro: resp.logradouro,
          complemento: '',
          numero: '',
          bairro: resp.bairro.nome,
          cidade: resp.bairro.cidade.nome,
          estado: resp.bairro.cidade.uf.descricao
        }
  
        setState((prevState) => ({
          ...prevState,
          cliente
        }))
        
      } catch (error) {
        cliente.endereco = {
          cep_id: '',
          cep: '',
          logradouro: '',
          complemento: '',
          numero: '',
          bairro: '',
          cidade: '',
          estado: '',
        }
  
        setState((prevState) => ({
          ...prevState,
          cliente,
          alerta: {
            open: true,
            severity: 'error',
            message: error.response ? error.response.data.message : 'Erro Interno'
          }
        }))
      }
    }
  }

  const openDocumento = (link, documento, formato) => {
    setState((prevState) => ({
      ...prevState,
      openModalVisualizarDocumento: true,
      documento: {
        nome: documento,
        url: link,
        formato
      }
    }))
  }

  const uploadDoc = async (pessoa_id, doc, solicitacao_id) => {
    try {
      let {cliente, pessoa} = state

      let files = cliente.files

      let file = files[doc]
      
      if(file.length > 0){
        const data = new FormData();
        data.append("file", file[0].file, file[0].name);

        await axios.post(`${window._env_.REACT_APP_API_URL}/pessoa/uploadDocumentoTemp/${pessoa_id}/${doc}/${pessoa.unidade_id}/${solicitacao_id}`, data )

        .catch((error) => {
          console.log(error)
          setState(prevState => ({...prevState,
            alerta: {
              open: true,
              severity: 'error',
              message: error.response ? error.response.data.message : 'Erro Interno'
            },
            loadingSalvar: false
          }))
        });
      }
      
    } catch (error) {
      console.log(error)
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        },
        loadingSalvar: false
      }))
    }
  }

  const confirmarAlteracaoCadastro = async (value) => {
    if(value){
      let {cliente, dadosComplementaresPF} = state
        
      if(cliente.tipoPessoa === "FISICA"){
        if(cliente.fisica.cpf === ""){
          setState(prevState => ({...prevState,
            alerta: {
              open: true,
              severity: 'warning',
              message: 'Por favor informar o seu CPF!'
            }
          }))
          return false
        }
        if(cliente.fisica.nome === ""){
          setState(prevState => ({...prevState,
            alerta: {
              open: true,
              severity: 'warning',
              message: 'Por favor informar o seu nome!'
            }
          }))
          return false
        }
        if(cliente.fisica.data_nascimento === ""){
          setState(prevState => ({...prevState,
            alerta: {
              open: true,
              severity: 'warning',
              message: 'Por favor informar a sua data de nascimento!'
            }
          }))
          return false
        }
        if(cliente.fisica.telefone === ""){
          setState(prevState => ({...prevState,
            alerta: {
              open: true,
              severity: 'warning',
              message: 'Por favor informar o seu telefone!'
            }
          }))
          return false
        }

        for (const item of dadosComplementaresPF.list) {
          if(item.obrigatorio){
            if(item.column_name === 'doc_rg_frente'){
              if(cliente.files[item.column_name].length === 0){
                setState(prevState => ({...prevState,
                  alerta: {
                    open: true,
                    severity: 'warning',
                    message: `Por favor informar o(a) ${item.column_comment}`
                  }
                }))
                return
              }
            }else if(item.column_name === 'doc_rg_verso'){
              if(cliente.files[item.column_name].length === 0){
                setState(prevState => ({...prevState,
                  alerta: {
                    open: true,
                    severity: 'warning',
                    message: `Por favor informar o(a) ${item.column_comment}`
                  }
                }))
                return
              }
            }else if(item.column_name === 'doc_cnh_frente'){
              if(cliente.files[item.column_name].length === 0){
                setState(prevState => ({...prevState,
                  alerta: {
                    open: true,
                    severity: 'warning',
                    message: `Por favor informar o(a) ${item.column_comment}`
                  }
                }))
                return
              }
            }else if(item.column_name === 'doc_cnh_verso'){
              if(cliente.files[item.column_name].length === 0){
                setState(prevState => ({...prevState,
                  alerta: {
                    open: true,
                    severity: 'warning',
                    message: `Por favor informar o(a) ${item.column_comment}`
                  }
                }))
                return
              }
            }else if(item.column_name === 'doc_comprovante_renda'){
              if(cliente.files[item.column_name].length === 0){
                setState(prevState => ({...prevState,
                  alerta: {
                    open: true,
                    severity: 'warning',
                    message: `Por favor informar o(a) ${item.column_comment}`
                  }
                }))
                return
              }
            }else if(item.column_name === 'doc_comprovante_endereco'){
              if(cliente.files[item.column_name].length === 0){
                setState(prevState => ({...prevState,
                  alerta: {
                    open: true,
                    severity: 'warning',
                    message: `Por favor informar o(a) ${item.column_comment}`
                  }
                }))
                return
              }
            }else if(cliente.fisica.dadosComplementar[item.column_name] === '' || !cliente.fisica.dadosComplementar[item.column_name]){
              setState(prevState => ({...prevState,
                alerta: {
                  open: true,
                  severity: 'warning',
                  message: `Por favor informar o(a) ${item.column_comment}`
                }
              }))
              return
            }
          }
        }
      }else{
        if(cliente.juridica.cnpj === ""){
          setState(prevState => ({...prevState,
            alerta: {
              open: true,
              severity: 'warning',
              message: 'Por favor informar o CNPJ da empresa!'
            }
          }))
          return false
        }
        if(cliente.juridica.razao_social === ""){
          setState(prevState => ({...prevState,
            alerta: {
              open: true,
              severity: 'warning',
              message: 'Por favor informar a razão social da empresa!'
            }
          }))
          return false
        }
        if(cliente.juridica.nome_fantasia === ""){
          setState(prevState => ({...prevState,
            alerta: {
              open: true,
              severity: 'warning',
              message: 'Por favor informar o nome fantasia da empresa!'
            }
          }))
          return false
        }
        if(cliente.juridica.data_fundacao === ""){
          setState(prevState => ({...prevState,
            alerta: {
              open: true,
              severity: 'warning',
              message: 'Por favor informar a data de fundação da empresa!'
            }
          }))
          return false
        }
        if(cliente.juridica.telefone === ""){
          setState(prevState => ({...prevState,
            alerta: {
              open: true,
              severity: 'warning',
              message: 'Por favor informar o telefone da empresa!'
            }
          }))
          return false
        }
      }

      if(cliente.endereco.cep === ''){
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'warning',
            message: 'Por favor informar o CEP do seu endereço!'
          }
        }))
        return false
      }
      if(cliente.endereco.logradouro === ''){
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'warning',
            message: 'Por favor informar o seu endereço!'
          }
        }))
        return false
      }
      if(cliente.endereco.numero === ''){
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'warning',
            message: 'Por favor informar o número do seu endereço!'
          }
        }))
        return false
      }

      try {
        setState(prevState => ({...prevState,
          loadingSalvar: true,
          alerta: {
            open: true,
            severity: 'info',
            message: 'Atualizando Cadastro...'
          }
        }))

        let dados = {
          tipoPessoa: cliente.tipoPessoa,
          fisica: cliente.fisica,
          juridica: cliente.juridica,
          endereco: cliente.endereco
        }
  
        if(cliente.tipoPessoa === "FISICA"){
          delete dados.juridica
  
          if(dados.fisica.dadosComplementar.rg_data_emissao === '') delete  dados.fisica.dadosComplementar.rg_data_emissao
          if(dados.fisica.dadosComplementar.data_casamento === '') delete  dados.fisica.dadosComplementar.data_casamento
          if(dados.fisica.dadosComplementar.rg_data_emissao_conjugue === '') delete  dados.fisica.dadosComplementar.rg_data_emissao_conjugue
          if(dados.fisica.dadosComplementar.data_nascimento_conjugue === '') delete  dados.fisica.dadosComplementar.data_nascimento_conjugue
          if(dados.fisica.dadosComplementar.sexo === '') delete  dados.fisica.dadosComplementar.sexo
          if(dados.fisica.dadosComplementar.como_conheceu_id === '') delete  dados.fisica.dadosComplementar.como_conheceu_id
          if(dados.fisica.dadosComplementar.quantidade_filhos === '') delete  dados.fisica.dadosComplementar.quantidade_filhos
        }else{
          delete dados.fisica
        }

        let{ data } = await axios.put(`${window._env_.REACT_APP_API_URL}/cliente/atualizacaoCadastroExterno/${cliente.pessoa_id}`, dados)

        for (const key in cliente.files) {
          await uploadDoc(cliente.pessoa_id, key, data.id)
        }

        setState(prevState => ({...prevState,
          loadingSalvar: false,
          alerta: {
            open: true,
            severity: 'info',
            message: 'Solicitação enviado para a empresa!'
          }
        }))

        handleCloseModalAlteracaoDadosCadastro()
        // window.location.reload()

      } catch (error) {
        console.log(error)
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'error',
            message: error.response ? error.response.data.message : 'Erro Interno'
          },
          loadingSalvar: false
        }))
      }
    }else{
      handleCloseModalAlteracaoDadosCadastro()
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      const helpPath = require("./../../help/boletosCliente/BoletosCliente.md")

      fetch(helpPath)
        .then(response => {
          return response.text()
        })
        .then(text => {
          setState(prevState => ({...prevState,
            markdown: text
          }))
        })
  
      let cliente = JSON.parse(localStorage.getItem('cliente'))

      let {filtro} = state

      if(cliente){
        if(cliente.unidade_id === 48 || cliente.unidade_id === 66){
          filtro.data_inicial = moment().format('YYYY-MM')+'-01'
          filtro.data_final = moment().format('YYYY-MM-DD')

          setState(prevState => ({
            ...prevState,
            disableFiltro: true
          }))
        }else{
          filtro.data_inicial = moment().format('YYYY-MM')+'-01'
          filtro.data_final = moment(filtro.data_inicial).add(1, 'month').subtract(1, 'day').format('YYYY-MM-DD')
        }
      }else{
        filtro.data_inicial = moment().format('YYYY-MM')+'-01'
        filtro.data_final = moment(filtro.data_inicial).add(1, 'month').subtract(1, 'day').format('YYYY-MM-DD')
      }  

      setState(prevState => ({...prevState,
        filtro,
        parcelas_antecipacao: []
      }))
  
      const tempoConexao = JSON.parse(localStorage.getItem('tempoConexao'))

      if (!cliente) {
        setState(prevState => ({...prevState,
          openModalConfirmar: true,
          openModalAlteracaoDadosCadastro: false
        }))
      } else if (cliente.id !== parseInt(pessoaId)) {
        setState(prevState => ({...prevState,
          openModalConfirmar: true,
          openModalAlteracaoDadosCadastro: false
        }))
      } else if (tempoConexao) {
        if (new Date(tempoConexao).getTime() < new Date().getTime()) {
  
          setState(prevState => ({...prevState,
            openModalConfirmar: true,
            openModalAlteracaoDadosCadastro: false
          }))
        } else if (!cliente) {
          setState(prevState => ({...prevState,
            openModalConfirmar: true,
            openModalAlteracaoDadosCadastro: false
          }))
        } else {
          let cliente = JSON.parse(localStorage.getItem('cliente'))
  
          setState(prevState => ({...prevState,
            pessoa: cliente,
            mostrarBoletos: true,
            openModalAlteracaoDadosCadastro: false
          }))
  
          filtrarContas(filtro)
        }
      } else {  
        if (pessoaId) {
          try {
            const { data: pessoa } = await axios.get(`${window._env_.REACT_APP_API_URL}/pessoa/${parseInt(pessoaId)}`, getToken())

            localStorage.setItem('cliente', JSON.stringify(pessoa))
  
            setState(prevState => ({...prevState,
              pessoa,
              openModalConfirmar: true,
              openModalAlteracaoDadosCadastro: false
            }))
          } catch (error) {
            console.log(error)
            setState(prevState => ({...prevState,
              alerta: {
                open: true,
                severity: 'error',
                message: error.response ? error.response.data.message : 'Erro Interno'
              }
            }))
          }
        }
      }
  
      let novoTempoConexao = moment(new Date()).add(3, 'minutes')
      localStorage.setItem('tempoConexao', JSON.stringify(novoTempoConexao))
      
      handleCloseModalQuitacao()
      handleCloseModalAnteciparParcelas()
    };
    
    fetchData();
  }, []);

  const { contas, mostrarBoletos, pessoa, filtro, contas_abertas, possui_antecipacao } = state

  console.log(state.dadosComplementaresPF)

  return (
    <div className="container-boletos" id="app">
      {state.loading &&
        <React.Fragment>
          <div className="progressCircular">
            <CircularProgress />
          </div>
          <div className="progressText">
            <p>Carregando Contas...</p>
          </div>
        </React.Fragment>
      }
      {!state.loading &&
        <React.Fragment>
          {mostrarBoletos &&
            <React.Fragment>
              <Grid container spacing={1} direction="row" className="borderBottom">
                <Grid item md={6} xs={12} sm={6}>
                  <Grid container spacing={1} direction="row">
                    <Grid item md={2} xs={3} sm={3}>
                      {pessoa.unidade_pertence.configuracao &&
                        <React.Fragment>
                          {pessoa.unidade_pertence.configuracao.midia &&
                            <img src={pessoa.unidade_pertence.configuracao.midia.caminho} alt="Logo Unidade" style={{width: '90%', marginLeft: '5%', marginBottom: 10}}/>
                          }
                          {!pessoa.unidade_pertence.configuracao.midia &&
                            <img src="./../../../images/BRAND_MAISFINANCEIRO.png" alt="Logo Mais Financeiro" style={{width: '90%', marginLeft: '5%', marginBottom: 10}}/>
                          }
                        </React.Fragment>
                      }
                      {!pessoa.unidade_pertence.configuracao &&
                        <React.Fragment>
                          <img src="./../../../images/BRAND_MAISFINANCEIRO.png" alt="Logo Mais Financeiro" style={{width: '90%', marginLeft: '5%', marginBottom: 10}}/>
                        </React.Fragment>
                      }
                    </Grid>
                    <Grid item md={10} xs={9} sm={9}>
                      <p style={{ textAlign: 'left', marginBottom: '0px' }}>Cliente: {pessoa.tipo === 'FISICA' ? pessoa.fisica.nome : pessoa.juridica.razaosocial}</p>
                      <p style={{ textAlign: 'left', marginBottom: '0px' }}>Unidade: {pessoa.unidade_pertence.descricao}</p>
                      {pessoa.unidade_pertence.configuracao &&
                        <React.Fragment>
                          {pessoa.unidade_pertence.configuracao.hab_alt_cad_externo &&
                            <Button size='small' variant="contained" color="warning" onClick={() => atualizarDadosCadastro()}>
                              Atualizar Dados
                            </Button>
                          }
                        </React.Fragment>
                      }
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item md={6} xs={12} sm={6}>
                  <Grid container spacing={1} direction="row">
                    <Grid item md={10} xs={9} sm={9}>
                      <h1 className="titulo" style={{ textAlign: 'right', marginTop: '15px' }}>Minhas Contas</h1>
                    </Grid>
                    <Grid item md={2} xs={3} sm={3}>
                      <img src="./../../../images/BRAND_MAISFINANCEIRO.png" alt="Logo Mais Financeiro" />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid style={{ paddingBottom: '10px' }} container spacing={1} direction="row" className="borderBottom mg_top_20">
                <Grid item md={2} xs={12} sm={6}>
                  <TextField 
                    className="input" 
                    type="date" 
                    label="Data Inicial" 
                    variant="outlined" 
                    size="small" 
                    name="data_inicial" 
                    value={filtro.data_inicial} 
                    onChange={(e) => updateFiltro(e)} 
                    InputLabelProps={{ shrink: true }}
                    disabled={state.disableFiltro}
                  />
                </Grid>
                <Grid item md={2} xs={12} sm={6}>
                  <TextField 
                    className="input" 
                    type="date" 
                    label="Data Final" 
                    variant="outlined" 
                    size="small" 
                    name="data_final" 
                    value={filtro.data_final} 
                    onChange={(e) => updateFiltro(e)} 
                    InputLabelProps={{ shrink: true }} 
                    disabled={state.disableFiltro}
                  />
                </Grid>
                <Grid item md={2} xs={12} sm={6}>
                  <TextField
                    id="standard-select-currency"
                    select
                    label="Situação"
                    variant="outlined"
                    className="input"
                    fullWidth
                    size="small"
                    SelectProps={{
                      native: true,
                    }}
                    name="situacao"
                    value={filtro.situacao}
                    onChangeCapture={(e) => updateFiltro(e)}
                    InputLabelProps={{ shrink: true }}
                    disabled={state.disableFiltro}
                  >
                    <option value=""></option>
                    <option value="A VENCER">A Pagar</option>
                    <option value="BAIXADO">Pago</option>
                    <option value="VENCIDO">Atrasado</option>
                    <option value="A VENCER E VENCIDO">Atrasado e A Pagar</option>
                  
                  </TextField>
                </Grid>
                <Grid item md={2} xs={12} sm={3}>
                  <Button fullWidth variant="contained" color="primary" onClick={() => filtrarContas(state.filtro)}>
                    Filtrar
                  </Button>
                </Grid>
                <Grid item md={3} xs={12} sm={3}>
                  {!possui_antecipacao &&
                    <Button fullWidth variant="contained" color="success" onClick={() => openQuitacao()}>
                      Antecipar Parcelas
                    </Button>
                  }
                  {possui_antecipacao &&
                    <Button fullWidth variant="contained" color="secondary" onClick={() => cancelarAntecipacao()}>
                      Cancelar Antecipações
                    </Button>
                  }
                </Grid>
              </Grid>
              <Grid container spacing={1} direction="row" className="mg_top_10">
                {contas.list.map((value, key) => {
                  return (
                    <Grid key={key} item md={3} xs={12} sm={4}>
                      <div
                        style={{
                          width: '96%',
                          marginLeft: '2%',
                          border: '1px solid #b0b0b0',
                          backgroundColor: renderClassFinanceiro(value.status),
                          minHeight: 140,
                          borderRadius: 5,
                          overflow: 'hidden',
                          position: 'relative'
                        }}
                      >
                        <Grid container spacing={1} direction="row">
                          <Grid item md={12} xs={12} sm={12} style={{textTransform: 'uppercase'}}>
                            <b><p style={{fontSize: '12px', margin: '5px 10px 5px 10px'}}>{value.descricao}</p></b>
                            <b><p style={{fontSize: '12px', margin: '5px 10px 5px 10px'}}>Vencimento: {value.data_vencimento}</p></b>
                            <b><p style={{fontSize: '12px', margin: '5px 10px 5px 10px'}}>Valor: R$ <span>{real(value.valor_total)} - Situação: {value.status}</span></p></b>
                            {(value.forma_pgto_slug === 'boleto' && !value.parceiro_bancario_id) &&
                              <b><p style={{fontSize: '10px', margin: '5px 10px 5px 10px', color: 'red'}}><i>Boleto emitido por outra instituição financeira</i></p></b>
                            }
                            {(value.forma_pgto_slug === 'pix' && !value.parceiro_bancario_id) &&
                              <b><p style={{fontSize: '10px', margin: '5px 10px 5px 10px', color: 'red'}}><i>Pix emitido por outra instituição financeira</i></p></b>
                            }
                          </Grid>
                        </Grid>
                        <Grid container spacing={1} direction="row" style={{height: '40px', backgroundColor: 'rgba(169,169,169,0.8)', position: 'absolute', bottom: 0}}>
                          <Grid item md={4} xs={4} sm={4} style={{textAlign: 'center'}}>
                            <Tooltip title='Visualizar Contas' aria-label="add">
                              <IconButton onClick={e => viewRow(value)} color="primary" aria-label="view" style={{padding: '0px'}}>
                                <VisibilityIcon />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                          {value.nfse_ids.length > 0 &&
                            <Grid item md={4} xs={4} sm={4} style={{textAlign: 'center'}}>
                              <Tooltip title='Visualizar NFS-e' aria-label="add">
                                <img className='icone_nfse' src={icone_nfse} alt="Icone NFS-e" onClick={e => viewNFSe(value)}/>
                              </Tooltip>
                            </Grid>
                          }
                          {value.nfse_ids.length === 0 &&
                            <Grid item md={4} xs={4} sm={4} style={{textAlign: 'center'}}>
                              <img className='icone_nfse' src={icone_nfse_disabled} alt="Icone NFS-e"/>
                            </Grid>
                          }
                          <Grid item md={4} xs={4} sm={4} style={{textAlign: 'center'}}>
                            <Tooltip title='Visualizar Boleto/Pix' aria-label="add">
                              <IconButton onClick={e => imprimirBoleto(value)} color="success" aria-label="view" style={{padding: '0px'}} disabled={!value.parceiro_bancario_id}>
                                <PulsingIcon />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                        </Grid>
                      </div>
                    </Grid>
                  )
                })}
              </Grid>
              <span className='clicksistemas'>Desenvolvido por Click Sistemas LTDA 2021 ® </span>
            </React.Fragment>
          }
          {/* <Tooltip title='Ajuda' aria-label="add" arrow>
            <HelpIcon style={{bottom: 20}} className='botao_ajuda' onClick={() => {setState(prevState => ({...prevState,openModalAjuda: true}))}}/>
          </Tooltip> */}
        </React.Fragment>
      }
      <ConfirmarCPFCNPJ
        open={state.openModalConfirmar}
        dados={state.login}
        confirmar={e => confirmar(e)}
        updateData={(e) => updateField(e)}
        openModalAjuda={() => {setState(prevState => ({...prevState,openModalAjuda: true}))}}
      />

      <ModalExibeBoleto
        open={state.openModalExibeBoleto}
        handleClose={e => handleModalExibeBoleto()}
        boletoSelecionado={state.boletoSelecionado}
        copyLink={e => copyLink(e)}
      />

      <ModalViewContaCliente
        open={state.openModalViewContaCliente}
        dados={state.dataSelecionada}
        handleClose={e => handleModalViewContaCliente()}
        renderClassFinanceiro={(e) => renderClassFinanceiro(e)}
        voltar={e => handleModalViewContaCliente()}
      />

      <Alert
        open={state.alerta.open}
        handleClose={e => handleCloseAlerta()}
        severity={state.alerta.severity}
        message={state.alerta.message} 
      />

      <ModalConfirmacao 
        open={state.modalOpen} 
        handleClose={e => handleClose(e)} 
        dados={state.modal} 
        confirmar={e => continuaImprimirBoletoPix(e, state.row_selecionado)}
      />

      <ModalAjuda 
        open={state.openModalAjuda}
        tela={"Minhas Contas"}
        handleClose={() => {setState(prevState => ({...prevState,openModalAjuda: false}))}}
        markdown={state.markdown}
      />

      <ModalAnteciparParcelas
        open={state.openModalAnteciparParcelas}
        handleClose={e => handleCloseModalAnteciparParcelas()}
        contas={contas_abertas.list}
        selecionarParcela={(e, key) => selecionarParcelaAntecipar(e, key)}
        parcelas_antecipacao={state.parcelas_antecipacao}
        selecionarTodos={() => selecionarTodos()}
        desmarcarTodos={() => desmarcarTodos()}
        selecionar={state.selecionarTodos}
        confirmarAntecipacao={() => confirmarAntecipacao()}
      />

      <ModalQuitacao 
        open={state.openModalQuitacao}
        handleClose={e => handleCloseModalQuitacao()}
        contas={contas_abertas.list}
        openSelecionarParcelas={() => openSelecionarParcelas()}
        quitarParcelas={() => quitarParcelas()}
      />

      <ModalAlteracaoDadosCadastro
        open={state.openModalAlteracaoDadosCadastro}
        handleClose={e => handleCloseModalAlteracaoDadosCadastro()}
        cliente={state.cliente}
        metodos_divugacoes={state.metodos_divugacoes}
        updateFieldTipo={(e) => updateFieldTipo(e)}
        updateFieldFisica={(e) => updateFieldFisica(e)}
        updateFieldJuridica={(e) => updateFieldJuridica(e)}
        dadosComplementaresPF={state.dadosComplementaresPF}
        updateFieldDadosComplementar={(e) => updateFieldDadosComplementar(e)}
        validarCpfCnpjConjugue={(e) => validarCpfCnpjConjugue(e)}
        handleUpload={(file, name) => handleUpload(file, name)}
        updateFieldEndereco={(e) => updateFieldEndereco(e)}
        buscaEndereco={(e) => buscaEndereco(e)}
        openDocumento={(link, documento, formato) => openDocumento(link, documento, formato)}
        confirmar={(e) => confirmarAlteracaoCadastro(e)}
        loadingSalvar={state.loadingSalvar}
      />

      <ModalVisualizarDocumento 
        open={state.openModalVisualizarDocumento}
        handleClose={e => handleModalVisualizarDocumento()}
        documento={state.documento}
      />
    </div>
  )
}

export default BoletosCliente