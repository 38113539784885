import React, { useState, useEffect } from 'react';
import './AlteracaoCadastro.scss'
import './../../main/ultil.scss'
import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import moment from 'moment';
import axios from 'axios'
import Alert from './../../components/templates/Alert'
import Loading from './../../components/templates/Loading'
import { useParams, useNavigate } from 'react-router-dom';
import initialState from './initialState/view';
import getToken from './../../services/getToken'
import { Grid, TextField, FormControlLabel, Button, Switch, FormLabel, FormControl } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ModalVisualizarDocumento from './../../components/modals/VisualizarDocumento'
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import decrypt from '../../services/decrypt'
import formatarTelefone from './../../services/formatarTelefone'

let formatos = [
  {
    type: "text",
    data_type: "character varying"
  },
  {
    type: "date",
    data_type: "date"
  },
  {
    type: "number",
    data_type: "integer"
  },
]

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
    value: index
  };
}

function formataTelefone(numero) {

  if(numero){
    let texto = numero.trim()

		texto = texto.replace(/\D/g,""); 
    
    if(texto.length === 11 || texto.length === 10){
      texto = texto.replace(/^(\d{2})(\d)/g,"($1) $2")
      texto = texto.replace(/(\d)(\d{4})$/,"$1-$2")
    }else if(texto.length === 12){
      texto = texto.replace(/^(\d{3})(\d)/g,"($1) $2")
      texto = texto.replace(/(\d)(\d{4})$/,"$1-$2")
    }else if(texto.length === 8){
      texto = texto.replace(/(\d)(\d{4})$/,"$1-$2")
    }
  
    return texto
  }

  return ''
}

const cpf = require('node-cpf-cnpj');

const ViewCliente = () => {
  const [state, setState] = useState({ ...initialState });
  const navigate = useNavigate();
  const { solicitacaoId } = useParams();
  const [thumbnail, setThumbnail] = useState(null);

  const criaUrl = () =>{
    const filtro = JSON.parse(localStorage.getItem('filtro_pessoas'))

    let url = `/solicitacao_alteracao_cadastro?limit=${filtro.limit}&offset=${filtro.offset}`

    if(filtro.filtrar_por !== ''){
      url += `&filtrar_por=${filtro.filtrar_por.replaceAll(' ', '%')}&value=${filtro.value.replaceAll(' ', '%')}`
    }

    if(filtro.nome !== ''){
      url += `&nome=${filtro.nome.replaceAll(' ', '%')}`
    }

    if(filtro.cpf_cnpj !== ''){
      url += `&cpf_cnpj=${filtro.cpf_cnpj.replaceAll(' ', '')}`
    }

    if(filtro.telefone !== ''){
      url += `&telefone=${filtro.telefone.replaceAll(' ', '')}`
    }

    return url
  }

  const backPage = (timeout = 3000) => {
    setTimeout(() => {
      let url = criaUrl()

      navigate(url);
    }, timeout)
  }

  const handleCloseAlerta = () => {
    setState(prevState => ({...prevState,
      alerta: {
        open: false,
        autoHideDuration: 5000,
        severity: state.alerta.severity,
        message: ''
      }
    }))
  }

  const openDocumento = (link, documento, formato) => {
    setState((prevState) => ({
      ...prevState,
      openModalVisualizarDocumento: true,
      documento: {
        nome: documento,
        url: link,
        formato
      }
    }))
  }

  const handleModalVisualizarDocumento = () => {
    setState((prevState) => ({
      ...prevState,
      openModalVisualizarDocumento: false,
      documento: {
        nome: '',
        url: ''
      }
    }))
  }

  const getDadoComplementarUnidade = async (unidade_id) =>{
    try {
      const { data } = await axios.get(`${window._env_.REACT_APP_API_URL}/dadosComplementaresPFExterno/list/${unidade_id}`)

      let dadosComplementaresPF = JSON.parse(decrypt(data))

      setState(prevState => ({...prevState,
        dadosComplementaresPF: {
          list: dadosComplementaresPF
        }
      }))
    } catch (error) {
      console.log(error)
      // setState(prevState => ({...prevState,
      //   alerta: {
      //     open: true,
      //     severity: 'error',
      //     message: error.response ? error.response.data.message : 'Erro Interno'
      //   }
      // }))
      return false
    }
  }
  
  const consultaMetodosDivugacao = async (unidade_id) => {
    try {
      const { data } = await axios.get(`${window._env_.REACT_APP_API_URL}/metodoDivugacaoExterno/${unidade_id}`)
      
      setState(prevState => ({...prevState,
        metodos_divugacoes:{
          list: JSON.parse(decrypt(data))
        }
      }))

    } catch (error) {
      console.log(error)
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      }))
    }
  }

  const consultarDados = async () => {
    try {
      const { data } = await axios.get(`${window._env_.REACT_APP_API_URL}/pessoa/solicitacaoAlteracao/${solicitacaoId}`, getToken())
      
      let dados_alterado = JSON.parse(data.campos)
      
      await getDadoComplementarUnidade(data.pessoa.unidade_id)
      await consultaMetodosDivugacao(data.pessoa.unidade_id)

      let files = {
        doc_rg_frente: [],
        doc_rg_verso: [],
        doc_cnh_frente: [],
        doc_cnh_verso: [],
        doc_comprovante_renda: [],
        doc_comprovante_endereco: [],
      }  

      if(dados_alterado.tipoPessoa === "FISICA"){
        for (const midia_solicitacao of data.midias) {
          let file = midia_solicitacao.midia
  
          files[midia_solicitacao.documento] = [{
            id: file.id,
            name: file.nome,
            preview: file.caminho,
            uploaded: true,
            url: file.caminho,
            formato: file.formato
          }]
        }
      }

      dados_alterado.files = files

      setState(prevState => ({...prevState,
        cliente: {...dados_alterado}
      }))

    }catch (error) {
      console.log(error)
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      }))
    }
  }

  const aprovarSolicitacao = async (value) => {
    try {
      let dados = {
        aprovar: value,
        solicitacao_id: solicitacaoId
      }

      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'info',
          message: 'Aprovando Cadastro...'
        },
        loadingSalvar: true
      }))

      await axios.post(`${window._env_.REACT_APP_API_URL}/pessoa/aprovarSolicitacaoAlteracao`,dados, getToken())
      
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'success',
          message: 'Cadastro Aprovando.'
        },
        loadingSalvar: true
      }))

      backPage()

    } catch (error) {
      console.log(error)
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        },
        loadingSalvar: false
      }))
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      const perfil = JSON.parse(localStorage.getItem('perfil'))
      let permissoes = perfil.permissoes.filter(param => param.tela.modulo.slug === 'solicitacao-alteracao-cadastro')

      setState(prevState => ({
        ...prevState,
        permissoes
      }))

      await consultarDados()
      
      setState(prevState => ({...prevState,
        loading: false
      }))
    };
    
    fetchData();
  }, []);

  let {cliente, dadosComplementaresPF, loadingSalvar} = state

  return (
    <React.Fragment>
      {state.loading &&
        <React.Fragment>
          <div className="progressCircular">
            <CircularProgress />
          </div>
          <div className="progressText">
            <p>Carregando Dados da Pessoa...</p>
          </div>
        </React.Fragment>
      }
      {!state.loading &&
        <div className="app-menu-closed" id="app">
          <Main openModalAjuda={() => {setState(prevState => ({...prevState,openModalAjuda: true}))}}>
            <div id='view'>
              <Grid container spacing={1} direction="row" className="borderBottom">
                <Grid item md={12} xs={12} sm={12}>
                  <h1 className="titulo">Aprovação Alteração do Cadastro de Cliente</h1>
                </Grid>
              </Grid>
              <Grid container direction="row" spacing={1} className="mg_top_10">
                <Grid item md={12} xs={12} sm={12}>
                  <h5>Dados Básicos:</h5>
                </Grid>
              </Grid>
              {cliente.tipoPessoa === 'FISICA' &&
                <React.Fragment>
                  <Grid container direction="row" spacing={1} className="mg_top_10">
                    <Grid item md={3} xs={12} sm={6}>
                      <TextField
                        id="standard-select-currency"
                        select
                        label="Tipo Pessoa*"
                        variant="outlined"
                        className="input"
                        fullWidth
                        size="small"
                        SelectProps={{
                          native: true,
                        }}
                        name="tipoPessoa"
                        value={cliente.tipoPessoa}
                        disabled
                      >
                        <option value="FISICA">
                          Pessoa Física
                        </option>
                        <option value="JURIDICA">
                          Pessoa Júridica
                        </option>
                      </TextField>
                    </Grid>
                    <Grid item md={3} xs={12} sm={6}>
                      <TextField className="input" label="CPF*" variant="outlined" size="small" name="cpf" value={cliente.fisica.cpf} disabled InputLabelProps={{ shrink: true }} />
                    </Grid>
                    <Grid item md={3} xs={12} sm={8}>
                      <TextField className="input" label="Nome*" variant="outlined" size="small" name="nome" value={cliente.fisica.nome} disabled InputLabelProps={{ shrink: true }} />
                    </Grid>
                    <Grid item md={3} xs={12} sm={8}>
                      <TextField className="input" label="Sobrenome" variant="outlined" size="small" name="sobrenome" value={cliente.fisica.sobrenome} disabled InputLabelProps={{ shrink: true }} />
                    </Grid>
                  </Grid>
                  <Grid container direction="row" spacing={1} className="mg_top_10">
                    <Grid item md={3} xs={12} sm={8}>
                      <TextField type='date' className="input" label="Data de Nascimento*" variant="outlined" size="small" name="data_nascimento" value={cliente.fisica.data_nascimento} disabled InputLabelProps={{ shrink: true }} />
                    </Grid>
                    <Grid item md={3} xs={12} sm={8}>
                      <TextField className="input" label="Celular*" variant="outlined" size="small" name="telefone" value={formatarTelefone(cliente.fisica.telefone)} disabled InputLabelProps={{ shrink: true }} />
                    </Grid>
                    <Grid item md={6} xs={12} sm={8}>
                      <TextField className="input" label="E-mail" variant="outlined" size="small" name="email" value={cliente.fisica.email} disabled InputLabelProps={{ shrink: true }} />
                    </Grid>
                  </Grid>
                </React.Fragment>
              }
              {cliente.tipoPessoa === 'JURIDICA' &&
                <React.Fragment>
                  <Grid container direction="row" spacing={1} className="mg_top_10">
                    <Grid item md={3} xs={12} sm={6}>
                      <TextField
                        id="standard-select-currency"
                        select
                        label="Tipo Pessoa*"
                        variant="outlined"
                        className="input"
                        fullWidth
                        size="small"
                        SelectProps={{
                          native: true,
                        }}
                        name="tipoPessoa"
                        value={cliente.tipoPessoa}
                        disabled
                      >
                        <option value="FISICA">
                          Pessoa Física
                        </option>
                        <option value="JURIDICA">
                          Pessoa Júridica
                        </option>
                      </TextField>
                    </Grid>
                    <Grid item md={3} xs={12} sm={6}>
                      <TextField className="input" label="CNPJ*" variant="outlined" size="small" name="cnpj" value={cliente.juridica.cnpj} disabled InputLabelProps={{ shrink: true }} />
                    </Grid>
                    <Grid item md={3} xs={12} sm={8}>
                      <TextField className="input" label="Razão Social*" variant="outlined" size="small" name="razao_social" value={cliente.juridica.razao_social} disabled InputLabelProps={{ shrink: true }} />
                    </Grid>
                    <Grid item md={3} xs={12} sm={8}>
                      <TextField className="input" label="Nome Fantasia*" variant="outlined" size="small" name="nome_fantasia" value={cliente.juridica.nome_fantasia} disabled InputLabelProps={{ shrink: true }} />
                    </Grid>
                  </Grid>
                  <Grid container direction="row" spacing={1} className="mg_top_10">
                    <Grid item md={3} xs={12} sm={8}>
                      <TextField type='date' className="input" label="Data de Fundação*" variant="outlined" size="small" name="data_fundacao" value={cliente.juridica.data_fundacao} disabled InputLabelProps={{ shrink: true }} />
                    </Grid>
                    <Grid item md={3} xs={12} sm={8}>
                      <TextField className="input" label="Celular*" variant="outlined" size="small" name="telefone" value={formatarTelefone(cliente.juridica.telefone)} disabled InputLabelProps={{ shrink: true }} />
                    </Grid>
                    <Grid item md={6} xs={12} sm={8}>
                      <TextField className="input" label="E-mail" variant="outlined" size="small" name="email" value={cliente.juridica.email} disabled InputLabelProps={{ shrink: true }} />
                    </Grid>
                  </Grid>
                </React.Fragment>
              }
              {(cliente.tipoPessoa === 'FISICA' && dadosComplementaresPF.list.length > 0) &&
                <React.Fragment>
                  <Grid container direction="row" spacing={1} className="mg_top_10">
                    <Grid item md={12} xs={12} sm={12}>
                      <h5>Dados Complementares:</h5>
                    </Grid>
                  </Grid>
                  <Grid container direction="row" spacing={1} className="mg_top_10">
                    {dadosComplementaresPF.list.sort((a, b) => (a.ordem > b.ordem) ? 1 : ((b.ordem > a.ordem) ? -1 : 0)).map(value => {
                      if(value.column_name === 'estado_civil'){
                        return (
                          <Grid key={value.dtd_identifier} item md={3} xs={12} sm={6}>
                            <TextField
                              id="standard-select-currency"
                              select
                              variant="outlined"
                              className="input"
                              fullWidth
                              size="small"
                              SelectProps={{
                                native: true,
                              }}
                              name={value.column_name}
                              label={`${value.column_comment}${value.obrigatorio ? '*': ''}`}
                              value={cliente.fisica.dadosComplementar[value.column_name]}
                              disabled
                              InputLabelProps={{shrink: true}} 
                            >
                              <option value="">Selecionar Estado Civil</option>
                              <option value="CASADO">Casado(a)</option>
                              <option value="SOLTEIRO">Solteiro(a)</option>
                              <option value="VIUVO">Viuvo(a)</option>
                              <option value="SEPARADO">Separado(a)</option>
                            </TextField>
                          </Grid>
                        )
                      }else if(value.column_name === 'sexo'){
                        return (
                          <Grid key={value.dtd_identifier}  item md={3} xs={12} sm={6}>
                            <TextField
                              id="standard-select-currency"
                              select
                              variant="outlined"
                              className="input"
                              fullWidth
                              size="small"
                              SelectProps={{
                                native: true,
                              }}
                              name={value.column_name}
                              label={`${value.column_comment}${value.obrigatorio ? '*': ''}`}
                              value={cliente.fisica.dadosComplementar[value.column_name]}
                              disabled
                              InputLabelProps={{shrink: true}} 
                            >
                              <option value="">Selecionar Sexo</option>
                              <option value="MASCULINO">Masculino</option>
                              <option value="FEMININO">Feminino</option>
                            </TextField>
                          </Grid>
                        )
                      }else if(value.column_name === 'rg_uf'){
                        return (
                          <Grid key={value.dtd_identifier}  item md={3} xs={12} sm={6}>
                            <TextField
                              id="standard-select-currency"
                              select
                              variant="outlined"
                              className="input"
                              fullWidth
                              size="small"
                              SelectProps={{
                                native: true,
                              }}
                              name={value.column_name}
                              label={`${value.column_comment}${value.obrigatorio ? '*': ''}`}
                              value={cliente.fisica.dadosComplementar[value.column_name]}
                              disabled
                              InputLabelProps={{shrink: true}} 
                            >
                              <option value="">Selecionar Estado</option>
                              <option value="AC">Acre</option>
                              <option value="AL">Alagoas</option>
                              <option value="AP">Amapá</option>
                              <option value="AM">Amazonas</option>
                              <option value="BA">Bahia</option>
                              <option value="CE">Ceará</option>
                              <option value="DF">Distrito Federal</option>
                              <option value="ES">Espírito Santo</option>
                              <option value="GO">Goiás</option>
                              <option value="MA">Maranhão</option>
                              <option value="MT">Mato Grosso</option>
                              <option value="MS">Mato Grosso do Sul</option>
                              <option value="MG">Minas Gerais</option>
                              <option value="PA">Pará</option>
                              <option value="PB">Paraíba</option>
                              <option value="PR">Paraná</option>
                              <option value="PE">Pernambuco</option>
                              <option value="PI">Piauí</option>
                              <option value="RJ">Rio de Janeiro</option>
                              <option value="RN">Rio Grande do Norte</option>
                              <option value="RS">Rio Grande do Sul</option>
                              <option value="RO">Rondônia</option>
                              <option value="RR">Roraima</option>
                              <option value="SC">Santa Catarina</option>
                              <option value="SP">São Paulo</option>
                              <option value="SE">Sergipe</option>
                              <option value="TO">Tocantins</option>
                            </TextField>
                          </Grid>
                        )
                      }else if(value.column_name === 'rg_orgao_emissor'){
                        return (
                          <Grid key={value.dtd_identifier}  item md={3} xs={12} sm={6}>
                            <TextField
                              id="standard-select-currency"
                              select
                              variant="outlined"
                              className="input"
                              fullWidth
                              size="small"
                              SelectProps={{
                                native: true,
                              }}
                              name={value.column_name}
                              label={`${value.column_comment}${value.obrigatorio ? '*': ''}`}
                              value={cliente.fisica.dadosComplementar[value.column_name]}
                              disabled
                              InputLabelProps={{shrink: true}} 
                            >
                              <option value="">Selecionar Orgão Emissor</option>
                              <option value="SSP - Secretaria de Segurança Pública">SSP - Secretaria de Segurança Pública</option>
                              <option value="PM - Polícia Militar">PM - Polícia Militar</option>
                              <option value="PC - Policia Civil">PC - Policia Civil</option>
                              <option value="CNT - Carteira Nacional de Habilitação">CNT - Carteira Nacional de Habilitação</option>
                              <option value="CRM - Conselho Regional de Medicina">CRM - Conselho Regional de Medicina</option>
                              <option value="CREA - Conselho Regional de Engenharia e Arquitetura">CREA - Conselho Regional de Engenharia e Arquitetura</option>
                              <option value="DIC - Diretoria de Identificação Civil">DIC - Diretoria de Identificação Civil</option>
                              <option value="CTPS - Carteira de Trabaho e Previdência Social">CTPS - Carteira de Trabaho e Previdência Social</option>
                              <option value="FGTS - Fundo de Garantia do Tempo de Serviço">FGTS - Fundo de Garantia do Tempo de Serviço</option>
                              <option value="IFP - Instituto Félix Pacheco">IFP - Instituto Félix Pacheco</option>
                              <option value="IPF - Instituto Pereira Faustino">IPF - Instituto Pereira Faustino</option>
                              <option value="IML - Instituto Médico-Legal">IML - Instituto Médico-Legal</option>
                              <option value="MTE - Ministério do Trabalho e Emprego">MTE - Ministério do Trabalho e Emprego</option>
                              <option value="MMA - Ministério da Marinha">MMA - Ministério da Marinha</option>
                              <option value="MAE - Ministério da Aeronáutica">MAE - Ministério da Aeronáutica</option>
                              <option value="ME - Ministério do Exército">ME - Ministério do Exército</option>
                              <option value="OAB - Ordem dos Advogados do Brasil">OAB - Ordem dos Advogados do Brasil</option>
                              <option value="POF - Polícia Federal">POF - Polícia Federal</option>
                              <option value="POM - Polícia Militar">POM - Polícia Militar</option>
                              <option value="SES - Carteira de Estrangeiro">SES - Carteira de Estrangeiro</option>
                              <option value="SJS - Secretaria da Justiça e Segurança">SJS - Secretaria da Justiça e Segurança</option>
                              <option value="SJTS - Secretaria da Justiça do Trabalho e Segurança">SJTS - Secretaria da Justiça do Trabalho e Segurança</option>
                              <option value="ZZZ - Outros (inclusive exterior)">ZZZ - Outros (inclusive exterior)</option>
                            </TextField>
                          </Grid>
                        )
                      }else if(value.column_name === 'rg_orgao_emissor_conjugue'){
                        return (
                          <Grid key={value.dtd_identifier}  item md={3} xs={12} sm={6}>
                            <TextField
                              id="standard-select-currency"
                              select
                              variant="outlined"
                              className="input"
                              fullWidth
                              size="small"
                              SelectProps={{
                                native: true,
                              }}
                              name={value.column_name}
                              label={`${value.column_comment}${value.obrigatorio ? '*': ''}`}
                              value={cliente.fisica.dadosComplementar[value.column_name]}
                              disabled
                              InputLabelProps={{shrink: true}} 
                            >
                              <option value=""></option>
                              <option value="SSP - Secretaria de Segurança Pública">SSP - Secretaria de Segurança Pública</option>
                              <option value="PM - Polícia Militar">PM - Polícia Militar</option>
                              <option value="PC - Policia Civil">PC - Policia Civil</option>
                              <option value="CNT - Carteira Nacional de Habilitação">CNT - Carteira Nacional de Habilitação</option>
                              <option value="CRM - Conselho Regional de Medicina">CRM - Conselho Regional de Medicina</option>
                              <option value="CREA - Conselho Regional de Engenharia e Arquitetura">CREA - Conselho Regional de Engenharia e Arquitetura</option>
                              <option value="DIC - Diretoria de Identificação Civil">DIC - Diretoria de Identificação Civil</option>
                              <option value="CTPS - Carteira de Trabaho e Previdência Social">CTPS - Carteira de Trabaho e Previdência Social</option>
                              <option value="FGTS - Fundo de Garantia do Tempo de Serviço">FGTS - Fundo de Garantia do Tempo de Serviço</option>
                              <option value="IFP - Instituto Félix Pacheco">IFP - Instituto Félix Pacheco</option>
                              <option value="IPF - Instituto Pereira Faustino">IPF - Instituto Pereira Faustino</option>
                              <option value="IML - Instituto Médico-Legal">IML - Instituto Médico-Legal</option>
                              <option value="MTE - Ministério do Trabalho e Emprego">MTE - Ministério do Trabalho e Emprego</option>
                              <option value="MMA - Ministério da Marinha">MMA - Ministério da Marinha</option>
                              <option value="MAE - Ministério da Aeronáutica">MAE - Ministério da Aeronáutica</option>
                              <option value="ME - Ministério do Exército">ME - Ministério do Exército</option>
                              <option value="OAB - Ordem dos Advogados do Brasil">OAB - Ordem dos Advogados do Brasil</option>
                              <option value="POF - Polícia Federal">POF - Polícia Federal</option>
                              <option value="POM - Polícia Militar">POM - Polícia Militar</option>
                              <option value="SES - Carteira de Estrangeiro">SES - Carteira de Estrangeiro</option>
                              <option value="SJS - Secretaria da Justiça e Segurança">SJS - Secretaria da Justiça e Segurança</option>
                              <option value="SJTS - Secretaria da Justiça do Trabalho e Segurança">SJTS - Secretaria da Justiça do Trabalho e Segurança</option>
                              <option value="ZZZ - Outros (inclusive exterior)">ZZZ - Outros (inclusive exterior)</option>
                            </TextField>
                          </Grid>
                        )
                      }else if(value.column_name === 'receber_novidades'){
                        return (
                          <Grid key={value.dtd_identifier}  item md={3} xs={12} sm={6}>
                            <TextField
                              id="standard-select-currency"
                              select
                              variant="outlined"
                              className="input"
                              fullWidth
                              size="small"
                              SelectProps={{
                                native: true,
                              }}
                              name={value.column_name}
                              label={`${value.column_comment}${value.obrigatorio ? '*': ''}`}
                              value={cliente.fisica.dadosComplementar[value.column_name]}
                              disabled
                              InputLabelProps={{shrink: true}} 
                            >
                              <option value="SIM">Sim</option>
                              <option value="NAO">Não</option>
                            </TextField>
                          </Grid>
                        )
                      }else if(value.column_name === 'como_conheceu_id'){
                        return (
                          <Grid key={value.dtd_identifier}  item md={3} xs={12} sm={6}>
                            <TextField
                              id="standard-select-currency"
                              select
                              variant="outlined"
                              className="input"
                              fullWidth
                              size="small"
                              SelectProps={{
                                native: true,
                              }}
                              name={value.column_name}
                              label={`${value.column_comment}${value.obrigatorio ? '*': ''}`}
                              value={cliente.fisica.dadosComplementar[value.column_name]}
                              disabled
                              InputLabelProps={{shrink: true}}
                            >
                            <option value={value.id}></option>
                            {state.metodos_divugacoes.list.map(value => {
                              return (
                                <option key={value.id} value={value.id}>{value.descricao}</option>
                              )
                            })}
                            </TextField>
                          </Grid>
                        )
                      }else if(value.column_name === 'telefone_conjugue'){
                        return (
                          <Grid key={value.dtd_identifier}  item md={3} xs={12} sm={6}>
                            <TextField className="input" label={`${value.column_comment}${value.obrigatorio ? '*': ''}`} variant="outlined" size="small" name={value.column_name} value={formatarTelefone(cliente.fisica.dadosComplementar[value.column_name])} disabled InputLabelProps={{ shrink: true }} />
                          </Grid>
                        )
                      }else if(value.column_name !== 'doc_rg_frente' &&
                        value.column_name !== 'doc_rg_verso' &&
                        value.column_name !== 'doc_cnh_frente' &&
                        value.column_name !== 'doc_cnh_verso' &&
                        value.column_name !== 'doc_comprovante_renda' &&
                        value.column_name !== 'doc_comprovante_endereco'){
                        return (
                          <Grid key={value.dtd_identifier} item md={3} xs={12} sm={6}>
                            <TextField
                              fullWidth
                              className="input"
                              type={formatos.filter(param => param.data_type === value.data_type)[0] ? formatos.filter(param => param.data_type === value.data_type)[0].type : 'text'}
                              name={value.column_name}
                              label={`${value.column_comment}${value.obrigatorio ? '*': ''}`}
                              value={cliente.fisica.dadosComplementar[value.column_name]}
                              variant="outlined"
                              size="small"
                              disabled
                              InputLabelProps={{shrink: true}} 
                            />
                          </Grid>
                        )
                      }
                    })}
                  </Grid>
                  <Grid container direction="row" spacing={1} className="mg_top_10">
                    {dadosComplementaresPF.list.sort((a, b) => (a.ordem > b.ordem) ? 1 : ((b.ordem > a.ordem) ? -1 : 0)).map(value => {
                      if(value.column_name === 'doc_rg_frente' && cliente.files['doc_rg_frente'][0]){
                        return (
                          <Grid key={value.dtd_identifier}  item md={2} xs={12} sm={6}>
                            <span style={{color: "#000", fontWeight: 400}}>RG Frente:</span>
                            <Tooltip title='Visualizar' aria-label="add">
                              <img src={cliente.files['doc_rg_frente'][0].preview} style={{cursor: 'pointer', width: '80%', marginLeft: '10%'}} onClick={() => openDocumento(cliente.files['doc_rg_frente'][0].preview, 'Comprovante de Endereço', cliente.files['doc_rg_frente'][0].formato)}/>
                            </Tooltip>
                          </Grid>
                        )
                      }else if(value.column_name === 'doc_rg_verso' && cliente.files['doc_rg_verso'][0]){
                        return (
                          <Grid key={value.dtd_identifier}  item md={2} xs={12} sm={6}>
                            <span style={{color: "#000", fontWeight: 400}}>RG Verso:</span>
                            <Tooltip title='Visualizar' aria-label="add">
                              <img src={cliente.files['doc_rg_verso'][0].preview} style={{cursor: 'pointer', width: '80%', marginLeft: '10%'}} onClick={() => openDocumento(cliente.files['doc_rg_verso'][0].preview, 'Comprovante de Endereço', cliente.files['doc_rg_verso'][0].formato)}/>
                            </Tooltip>
                          </Grid>
                        )
                      }else if(value.column_name === 'doc_cnh_frente' && cliente.files['doc_cnh_frente'][0]){
                        return (
                          <Grid key={value.dtd_identifier}  item md={2} xs={12} sm={6}>
                            <span style={{color: "#000", fontWeight: 400}}>CNH Frente:</span>
                            <Tooltip title='Visualizar' aria-label="add">
                              <img src={cliente.files['doc_cnh_frente'][0].preview} style={{cursor: 'pointer', width: '80%', marginLeft: '10%'}} onClick={() => openDocumento(cliente.files['doc_cnh_frente'][0].preview, 'Comprovante de Endereço', cliente.files['doc_cnh_frente'][0].formato)}/>
                            </Tooltip>
                          </Grid>
                        )
                      }else if(value.column_name === 'doc_cnh_verso' && cliente.files['doc_cnh_verso'][0]){
                        return (
                          <Grid key={value.dtd_identifier}  item md={2} xs={12} sm={6}>
                            <span style={{color: "#000", fontWeight: 400}}>CNH Verso:</span>
                            <Tooltip title='Visualizar' aria-label="add">
                              <img src={cliente.files['doc_cnh_verso'][0].preview} style={{cursor: 'pointer', width: '80%', marginLeft: '10%'}} onClick={() => openDocumento(cliente.files['doc_cnh_verso'][0].preview, 'Comprovante de Endereço', cliente.files['doc_cnh_verso'][0].formato)}/>
                            </Tooltip>
                          </Grid>
                        )
                      }else if(value.column_name === 'doc_comprovante_renda' && cliente.files['doc_comprovante_renda'][0]){
                        return (
                          <Grid key={value.dtd_identifier}  item md={2} xs={12} sm={6}>
                            <span style={{color: "#000", fontWeight: 400}}>Comp. de Renda:</span>
                            <Tooltip title='Visualizar' aria-label="add">
                              <img src={cliente.files['doc_comprovante_renda'][0].preview} style={{cursor: 'pointer', width: '80%', marginLeft: '10%'}} onClick={() => openDocumento(cliente.files['doc_comprovante_renda'][0].preview, 'Comprovante de Endereço', cliente.files['doc_comprovante_renda'][0].formato)}/>
                            </Tooltip>
                          </Grid>
                        )
                      }else if(value.column_name === 'doc_comprovante_endereco' && cliente.files['doc_comprovante_endereco'][0]){
                        return (
                          <Grid key={value.dtd_identifier}  item md={2} xs={12} sm={6}>
                            <span style={{color: "#000", fontWeight: 400}}>Comp. de Endereço:</span>
                            <Tooltip title='Visualizar' aria-label="add">
                              <img src={cliente.files['doc_comprovante_endereco'][0].preview} style={{cursor: 'pointer', width: '80%', marginLeft: '10%'}} onClick={() => openDocumento(cliente.files['doc_comprovante_endereco'][0].preview, 'Comprovante de Endereço', cliente.files['doc_comprovante_endereco'][0].formato)}/>
                            </Tooltip>
                          </Grid>
                        )
                      }
                    })}
                  </Grid>
                </React.Fragment>
              }
              <hr className="mg_top_10"/>
              <Grid container direction="row" spacing={1} className="mg_top_10">
                <Grid item md={8} xs={6} sm={4}></Grid>
                <Grid item md={2} xs={6} sm={4}>
                  <Button fullWidth color="secondary" variant="contained" className="btn_salvar" size="small" startIcon={<CloseIcon />} onClick={e => aprovarSolicitacao(false)} disabled={loadingSalvar}>Reprovar</Button>
                </Grid>
                <Grid item md={2} xs={6} sm={4}>
                  <Button fullWidth color="primary" variant="contained" className="btn_salvar" size="small" startIcon={<CheckIcon />} onClick={e => aprovarSolicitacao(true)} disabled={loadingSalvar}>Aprovar</Button>
                </Grid>
              </Grid>
            </div>
          </Main>
          <Nav/>
          <Alert
            open={state.alerta.open}
            handleClose={e => handleCloseAlerta()} 
            severity={state.alerta.severity}
            message={state.alerta.message} 
          />
        </div>
      }
      <Loading 
        open={state.loading}
        message={state.mensagemLoading} 
      />
      <ModalVisualizarDocumento 
        open={state.openModalVisualizarDocumento}
        handleClose={e => handleModalVisualizarDocumento()}
        documento={state.documento}
      />
    </React.Fragment>
  )

}

export default ViewCliente