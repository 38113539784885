import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

const AlertMenssager = ({open, autoHideDuration, handleClose, severity, message}) => (
  <div>
		<Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={open} autoHideDuration={autoHideDuration} onClose={handleClose}>
			<Alert
				onClose={handleClose}
				severity={severity}
				variant="filled"
				sx={{ width: '100%' }}
				data-cy="alert"
			>
				{message}
			</Alert>
		</Snackbar>
	</div>
)

export default AlertMenssager
