import React, { useState, useEffect } from 'react';
import './Nav.scss'
import axios from 'axios'
import { Grid, Button } from '@mui/material'
import { Link } from 'react-router-dom'
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import WorkIcon from '@mui/icons-material/Work';
import AccessibilityIcon from '@mui/icons-material/Accessibility';
import StoreIcon from '@mui/icons-material/Store';
import Tooltip from '@mui/material/Tooltip';
import ClickAwayListener from '@mui/material/ClickAwayListener'
import SyncAltIcon from '@mui/icons-material/SyncAlt'
import IconButton from '@mui/material/IconButton';
import NotificationsIcon from '@mui/icons-material/Notifications';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import socketClient from './../lib/socket'
import Alert from './../templates/Alert'
import bad from './bad.png'
import excellent from './excellent.png'
import regular from './regular.png'
import { useNavigate } from 'react-router-dom';
import { styled, keyframes } from "@mui/system";
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const shakeAnimation = keyframes`
  0% { transform: rotate(0deg); }
  10% { transform: rotate(-15deg); }
  20% { transform: rotate(15deg); }
  30% { transform: rotate(-10deg); }
  40% { transform: rotate(10deg); }
  50% { transform: rotate(-5deg); }
  60% { transform: rotate(5deg); }
  70% { transform: rotate(0deg); }
  100% { transform: rotate(0deg); }
`;

const ShakingIcon = styled(NotificationsIcon)`
  animation: ${shakeAnimation} 0.8s ease infinite;
  &:hover {
    animation-play-state: running;
  }
`;

const initialState = {
  itensMenu: [
    {
      title: "Home",
      subMenu: [],
      icone: 'fa fa-home',
      href: "/home",
      slug: ['home'],
      subMenuOpen: false,
      index: 1
    },
    {
      title: "Franquias",
      subMenu: [
        {
          title: "Representantes",
          subMenu: [],
          slug: ['representante'],
          icone: [<AccountTreeIcon className="iconMenu" />],
          href: "/representante",
          index: 2.1
        },
        {
          title: "Grupos Econômicos",
          subMenu: [],
          slug: ['franqueado'],
          icone: [<AccountTreeIcon className="iconMenu" />],
          href: "/franqueado",
          index: 2.1
        },
        {
          title: "Unidades",
          subMenu: [],
          slug: ['unidade'],
          icone: [<StoreIcon className="iconMenu" />],
          href: "/unidade",
          index: 2.2
        },
        {
          title: "Contratos Unidades",
          subMenu: [],
          slug: ['contrato-unidade'],
          icone: [<StoreIcon className="iconMenu" />],
          href: "/contratos_unidades",
          index: 2.3
        }
      ],
      icone: 'fa fa-user',
      href: "",
      subMenuOpen: false,
      index: 2
    },
    {
      title: "Recursos Humanos",
      subMenu: [
        {
          title: "Departamentos",
          subMenu: [],
          slug: ['departamento'],
          icone: [<WorkIcon className="iconMenu" />],
          href: "/departamentos",
          index: 3.1
        },
        {
          title: "Cargos",
          subMenu: [],
          slug: ['cargo'],
          icone: [<WorkIcon className="iconMenu" />],
          href: "/cargo",
          index: 3.2
        },
        {
          title: "Função",
          subMenu: [],
          slug: ['funcao'],
          icone: [<WorkIcon className="iconMenu" />],
          href: "/funcao",
          index: 3.3
        },
        {
          title: "Colaboradores",
          subMenu: [],
          slug: ['funcionario'],
          icone: [<AccessibilityIcon className="iconMenu" />],
          href: "/funcionario",
          index: 3.4
        },
      ],
      icone: 'fa fa-id-badge',
      href: "",
      subMenuOpen: false,
      index: 3
    },
    {
      title: "Produtos e Serviços",
      subMenu: [
        {
          title: "Grupo Produtos",
          subMenu: [],
          slug: ['grupo-produtos'],
          icone: '',
          href: "/grupo_produtos",
          index: 4.1
        },
        {
          title: "Sub-Grupo Produtos",
          subMenu: [],
          slug: ['sub-grupo-produtos'],
          icone: '',
          href: "/sub_grupo_produtos",
          index: 4.2
        },
        {
          title: "Produtos e Serviços",
          subMenu: [],
          slug: ['produto'],
          icone: '',
          href: "/produtos",
          index: 4.3
        },
        {
          title: "Tabela de Preços",
          subMenu: [],
          slug: ['tabela-precos'],
          icone: '',
          href: "/tabela_preco",
          index: 4.4
        },
        {
          title: "Configuração de Voucher",
          subMenu: [],
          slug: ['config-voucher'],
          icone: '',
          href: "/config_voucher",
          index: 4.5
        },
        {
          title: "Venda de Voucher",
          subMenu: [],
          slug: ['venda-voucher'],
          icone: '',
          href: "/venda_voucher",
          index: 4.6
        },
      ],
      icone: 'fa fa-product-hunt',
      href: "",
      subMenuOpen: false,
      index: 4
    },
    {
      title: "Clientes e Fornecedores",
      subMenu: [
        {
          title: "Clientes e Fornecedores",
          subMenu: [],
          slug: ['cliente'],
          icone: 'fa fa-user',
          href: "/cliente",
          index: 6.1
        },
        {
          title: "Pré Cadastro de Clientes",
          subMenu: [],
          slug: ['pre-cadastro-cliente'],
          icone: 'fa fa-user',
          href: "/pre_cadastro_cliente",
          index: 6.2
        },
        {
          title: "Solicitação de Alteração de Cadastro",
          subMenu: [],
          slug: ['solicitacao-alteracao-cadastro'],
          icone: 'fa fa-user',
          href: "/solicitacao_alteracao_cadastro",
          index: 6.3
        }
      ],
      slug: [],
      icone: 'fa fa-user',
      href: "",
      index: 6
    },
    {
      title: "Financeiro",
      subMenu: [
        {
          title: "Planos de Contas",
          subMenu: [],
          slug: ['plano-contas'],
          icone: '',
          href: "/plano_contas",
          index: 7.1
        },
        {
          title: "Item de Receita e Despesa",
          subMenu: [],
          slug: ['item-receita-despesa'],
          icone: '',
          href: "/item_receita_despesa",
          index: 7.2
        },
        {
          title: "Contas Bancárias",
          subMenu: [],
          slug: ['conta-financeira'],
          icone: '',
          href: "/conta_financeira",
          index: 7.3
        },
        {
          title: "Conciliação Bancária",
          subMenu: [],
          slug: ['movimentacao'],
          icone: '',
          href: "/movimentacao_financeira",
          index: 7.4
        },
        {
          title: "Contas a Pagar/Receber",
          subMenu: [],
          slug: ['contas-pagar-receber', 'caixa'],
          icone: '',
          href: "/conta_pagar_receber?limit=10&offset=0",
          index: 7.5
        },
        {
          title: "Pré Contas a Pagar/Receber",
          subMenu: [],
          slug: ['contas-pagar-receber'],
          icone: '',
          href: "/preContasPR",
          index: 7.6
        },
        {
          title: "Caixa",
          subMenu: [],
          slug: ['caixa'],
          icone: '',
          href: "/caixa",
          index: 7.7
        },
        {
          title: "Fechamento de Caixa",
          subMenu: [],
          slug: ['caixa_fechamento'],
          icone: '',
          href: "/caixa_fechamento",
          index: 7.8
        },
      ],
      icone: 'fa fa-money',
      href: "",
      subMenuOpen: false,
      index: 7
    },
    {
      title: "Vendas e Compras",
      subMenu: [
        {
          title: "Vendas",
          subMenu: [],
          slug: ['venda-servico-produto'],
          icone: 'fa fa-shopping-basket',
          href: "/vendas",
          index: 8.1
        },
        {
          title: "Compras",
          subMenu: [],
          slug: ['compra-servico-produto'],
          icone: 'fa fa-shopping-basket',
          href: "/compras",
          index: 8.2
        },
      ],
      icone: 'fa fa-shopping-basket',
      href: "/vendas",
      hiddenSubMenu: true,
      index: 8
    },
    {
      title: "Agenda",
      subMenu: [
        {
          title: "Agenda de Ambientes",
          subMenu: [],
          slug: ['agenda-ambiente'],
          icone: '',
          href: "/agenda_ambiente",
          index: 9.1
        },
        {
          title: "Ambientes",
          subMenu: [],
          slug: ['ambientes'],
          icone: '',
          href: "/ambiente",
          index: 9.2
        },
      ],
      icone: 'fa fa-calendar',
      href: "",
      hiddenSubMenu: true,
      index: 9
    },
    {
      title: "Perfil de Usuário",
      subMenu: [],
      slug: ['perfil'],
      icone: 'fa fa-user',
      href: "/perfil",
      index: 10
    },
    {
      title: "Relatórios",
      subMenu: [
        {
          title: "Administrativos",
          subMenu: [],
          slug: ['rel-adm'],
          icone: '',
          href: "/rel_adm",
          index: 11.1
        },
        {
          title: "Comissões",
          subMenu: [],
          slug: ['comissoes'],
          icone: '',
          href: "/comissoes",
          index: 11.2
        },
        {
          title: "Financeiros",
          subMenu: [],
          slug: ['rel-financeiro'],
          icone: '',
          href: "/rel_financeiro",
          index: 11.3
        },
        {
          title: "Fluxo de Caixa",
          subMenu: [],
          slug: ['fluxo-caixa-resumo'],
          icone: '',
          href: "/fluxo_caixa",
          index: 11.4
        },
        {
          title: "Vendas",
          subMenu: [],
          slug: ['rel-vendas'],
          icone: '',
          href: "/rel_vendas",
          index: 11.4
        },
        {
          title: "NFSE",
          subMenu: [],
          slug: ['nfse'],
          icone: '',
          href: "/rel_nfse",
          index: 11.5
        }
        
      ],
      icone: 'fa fa-bar-chart',
      href: "",
      hiddenSubMenu: true,
      index: 11
    },
    {
      title: "Admin",
      subMenu: [
        {
          title: "Serviços Bancários",
          subMenu: [],
          slug: ['servicos-bancario'],
          icone: '',
          href: "/servicos_bancario",
          index: 12.1
        },
        {
          title: "Parceiros Bancários",
          subMenu: [],
          slug: ['parceiros-bancario'],
          icone: '',
          href: "/parceiros_bancario",
          index: 12.2
        },
        {
          title: "Central de Notificações",
          subMenu: [],
          slug: ['central-alerta'],
          icone: '',
          href: "/central_notificacao",
          index: 12.3
        },
        {
          title: "API WhatsApp",
          subMenu: [],
          slug: ['api-whatsapp'],
          icone: '',
          href: "/api_whatsapp",
          index: 12.4
        },
        {
          title: "Pesquisas",
          subMenu: [],
          slug: ['pesquisa'],
          icone: '',
          href: "/pesquisa",
          index: 12.5
        },
        {
          title: "Template de Documentos",
          subMenu: [],
          slug: ['template-documentos'],
          icone: '',
          href: "/documentos",
          index: 12.6
        },
      ],
      icone: 'fa fa-cog',
      href: "",
      hiddenSubMenu: true,
      index: 12
    }
  ],
  menuFiltrado: [],
  term: "",
  menu_open: false,
  unidades_acesso: {},
  classMenuUnidadeClosed: "block",
  classMenuUnidadeOpen: "hidden",
  acesso_atual: [],
  notificacoes: [],
  openNotification: false,
  menuUnidade: 'close',
  openSwap: false,
  representantesAbertos: [],
  alerta: {
    open: false,
    severity: 'error',
    message: ''
  },
  velocidadeInternet: 0,
  unidades:{
    list: []
  },
  pendenciasUnidades: []
}

const Nav = () => {
  const [state, setState] = useState({ ...initialState });
  const navigate = useNavigate();

  const getToken = () => {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }
  
    return config
  }

  const getVelocidadeInternet = () => {
    let timeStart = performance.now();
    fetch(`${window._env_.REACT_APP_API_URL}`).then(response => {
      let timeEnd = performance.now();
      let tempoResposta = timeEnd - timeStart;
      let velocidadeInternet = (1024 / tempoResposta) * 8;

      setState(prevState => ({
        ...prevState,
        velocidadeInternet: parseInt(velocidadeInternet)
      }))
    });
  }

  const dropdown = (value) => {
    const menuFiltrado = state.menuFiltrado

    const indexMenuAberto = menuFiltrado.findIndex(item => item.subMenuOpen === true)
    const index = menuFiltrado.findIndex(x => x.index === value)

    if (indexMenuAberto !== -1) {
      if (indexMenuAberto !== index) {
        menuFiltrado[indexMenuAberto].subMenuOpen = false
      }
    }

    menuFiltrado[index].subMenuOpen = !state.menuFiltrado[index].subMenuOpen
    setState(prevState => ({...prevState, menuFiltrado }))
  }

  const closeMenu = () => {
    document.getElementById('app').className = "app-menu-closed"

    setState(prevState => ({
      ...prevState,
      menu_open: false
    }))
  }

  const openMenu = () => {
    document.getElementById('app').className = "app-menu-open"

    setState(prevState => ({
      ...prevState,
      menu_open: true
    }))
  }

  const openSwapNotification = () => {
    setState(prevState => ({...prevState,
      openNotification: !state.openNotification
    }))
  }

  const handleCloseAlerta = () => {
    setState(prevState => ({...prevState,
      alerta: {
        open: false,
        autoHideDuration: 5000,
        severity: state.alerta.severity,
        message: ''
      }
    }))
  }

  const renderTituloAcesso = () => {
    const { acesso_atual } = state

    if (acesso_atual.length === 1) {
      return (
        <p>Unidade Sel.:  {acesso_atual[0].numero ? `${acesso_atual[0].numero} - ${acesso_atual[0].descricao}` : acesso_atual[0].descricao}</p>
      )
    } else if (acesso_atual.length > 1) {
      return (
        <p>Unidade Sel.: Mais de um acesso simultâneo</p>
      )
    }
  }

  const handleClickAwayNotification = () => {
    if (state.openNotification) {
      setState(prevState => ({...prevState,
        openNotification: !state.openNotification
      }))
    }
  }

  const handleClickAway = () => {
    if (state.openSwap) {
      setState(prevState => ({...prevState,
        openSwap: !state.openSwap
      }))
    }
  }
  
  const updateFieldCheckbox = (value) => {
    const { acesso_atual } = state

    const index = acesso_atual.findIndex(acesso => acesso.id === value.id)

    if (index !== -1) {
      if (acesso_atual.length > 1) {
        acesso_atual.splice(index, 1);
      }
    } else {
      acesso_atual.push(value)
    }

    setState(prevState => ({...prevState,
      acesso_atual
    }))
  }

  const openRepresentante = (id) => {
    let {representantesAbertos} = state
    
    let index = representantesAbertos.indexOf(id)

    if(index === -1){
      representantesAbertos.push(id)
    }else{
      representantesAbertos.splice(index, 1)
    }

    localStorage.setItem('representantesAbertos', JSON.stringify(representantesAbertos));

    setState(prevState => ({...prevState,
      representantesAbertos
    }))
  }

  const trocarAcesso = () => {
    const { acesso_atual } = state
    localStorage.setItem('acesso_atual', JSON.stringify(acesso_atual));
    localStorage.removeItem('filtro_movimentacoes');
    localStorage.removeItem('filtro_contaspr');
    localStorage.removeItem('filtro_vendas');
    localStorage.removeItem('filtro_cliente_vendas');
    
    navigate("/home");
    window.location.reload()
    return;
  }

  const logout = () => {
    const usuario = JSON.parse(localStorage.getItem('usuario'))

    setState(prevState => ({...prevState,
      alerta: {
        open: true,
        severity: 'info',
        message: 'Encerrando sessão...'
      }
    }))

    if(usuario){
      socketClient({acao: 'user-logoff', values: usuario})
    }
    
    setTimeout(() => {
      localStorage.clear();

      localStorage.setItem('ultima_url_login', '/home')
      // window.location.reload()
      navigate('/')
      return;
    },  2000);
  }

  const openSwap = () => {
    setState(prevState => ({...prevState,
      openSwap: !state.openSwap
    }))
  }

  useEffect(() => {
    const fetchData = async () => {
      getVelocidadeInternet()

      const perfil = JSON.parse(localStorage.getItem('perfil'))
      const pessoa = JSON.parse(localStorage.getItem('pessoa'))
      const representantesAbertos = JSON.parse(localStorage.getItem('representantesAbertos'))

      setState(prevState => ({...prevState,
        representantesAbertos : representantesAbertos || []
      }))

      let acesso_atual = []

      const unidades_acesso = JSON.parse(localStorage.getItem('unidades_acesso'))
      const tipo = pessoa.fisica.funcionario.contrato.unidadetrabalho.tipo

      if (!localStorage.getItem('acesso_atual')) {
        if (tipo === 'MANTENEDOR') {
          acesso_atual = [
            {
              id: unidades_acesso.franqueador.id,
              descricao: unidades_acesso.franqueador.descricao,
              numero: unidades_acesso.franqueador.numero,
              tipo: unidades_acesso.franqueador.tipo
            }
          ]
        }if (tipo === 'REPRESENTANTE') {
          acesso_atual = [
            {
              id: unidades_acesso.representante.id,
              descricao: unidades_acesso.representante.descricao,
              numero: unidades_acesso.representante.numero,
              tipo: unidades_acesso.representante.tipo
            }
          ]
        } else if (tipo === 'GRUPOECONOMICO') {
          if(unidades_acesso.franquia.unidades.length === 1){
            acesso_atual = [
              {
                id: unidades_acesso.franquia.unidades[0].id,
                descricao: unidades_acesso.franquia.unidades[0].descricao,
                numero: unidades_acesso.franquia.unidades[0].numero,
                tipo: unidades_acesso.franquia.unidades[0].tipo
              }
            ]
          }else{
            acesso_atual = [
              {
                id: unidades_acesso.franquia.id,
                descricao: unidades_acesso.franquia.descricao,
                numero: unidades_acesso.franquia.numero,
                tipo: unidades_acesso.franquia.tipo
              }
            ]
          }
        } else if (tipo === 'UNIDADE') {
          acesso_atual = [
            {
              id: unidades_acesso.unidade.id,
              descricao: unidades_acesso.unidade.descricao,
              numero: unidades_acesso.unidade.numero,
              tipo: unidades_acesso.unidade.tipo
            }
          ]
        }
      } else {
        acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))
      }

      // let pendencias_unidade = JSON.parse(localStorage.getItem('pendenciasUnidades'))

      // if(pendencias_unidade){
      //   if (tipo === 'GRUPOECONOMICO') {
      //     if(pendencias_unidade.length > 0){
      //       setState(prevState => ({...prevState,
      //         alerta: {
      //           open: true,
      //           autoHideDuration: 3000,
      //           severity: 'warning',
      //           message: 'Sua unidade possui pendências financeiras com o sistema. Regularize-as para evitar futuros cortes de acesso!'
      //         }
      //       }))
      //     }
      //   }
      // }

      localStorage.setItem('acesso_atual', JSON.stringify(acesso_atual));

      const itensMenu = state.itensMenu

      let menuFiltrado = []

      if (perfil.unidade_id) {
        menuFiltrado.push(itensMenu[0])

        for (const item of itensMenu) {
          if (item.slug && item.slug[0] === 'home') continue

          if (item.subMenu.length === 0) {
            const indexSubMenu = perfil.permissoes.findIndex(param => {
              if (item.slug.includes(param.tela.modulo.slug)) return true
              return false
            })

            if(indexSubMenu !== -1){
              menuFiltrado.push(item)
            }
            continue
          }

          let itemMenuAux = { ...item }
          itemMenuAux.subMenu = []

          for (let j = 0; j < item.subMenu.length; j++) {
            if (item.subMenu[j].subMenu.length === 0) {
              const indexSubMenu = perfil.permissoes.findIndex(param => {
                if (item.subMenu[j].slug.includes(param.tela.modulo.slug)) return true
                return false
              })

              if (indexSubMenu !== -1) itemMenuAux.subMenu.push(item.subMenu[j])
            } else {

              let itemSubMenuAux = { ...item.subMenu[j] }
              itemSubMenuAux.subMenu = []

              for (let k = 0; k < item.subMenu[j].subMenu.length; k++) {
                const indexSubSubMenu = perfil.permissoes.findIndex(param => {
                    if (item.subMenu[j].subMenu[k].slug.includes(param.tela.modulo.slug)) return true
                  return false
                })

                if (indexSubSubMenu !== -1) {
                  itemSubMenuAux.subMenu.push(item.subMenu[j].subMenu[k])
                }
              }

              if (itemSubMenuAux.subMenu.length > 0) {
                itemMenuAux.subMenu.push(itemSubMenuAux)
              }
            }

          }

          if (itemMenuAux.subMenu.length > 0) {
            menuFiltrado.push(itemMenuAux)
          }
        }
      } else {
        menuFiltrado = itensMenu
      }

      setState(prevState => ({
        ...prevState,
        menuFiltrado,
        acesso_atual,
        dadosUsuario: pessoa,
        unidades_acesso
      }))

      try {
        const { data: ultimoCaixa } = await axios.get(`${window._env_.REACT_APP_API_URL}/caixa/ultimo`, getToken())
        
        const dados = {
          unidadesnegocio: [pessoa.fisica.funcionario.contrato.unidadetrabalho.id]
        }

        localStorage.setItem('ultimoCaixa', JSON.stringify(ultimoCaixa));

        setState(prevState => ({
        ...prevState,
          ultimoCaixa,
        }))
      } catch (error) {
        console.log(error)
      }

      try {

        const dados = {
          unidadesnegocio: acesso_atual.map(acesso => {
            return acesso.id
          })
        }

        // const { data } = await axios.post(`${window._env_.REACT_APP_API_URL}/notificacao`, dados, getToken())

        // setState(prevState => ({
        // ...prevState,
        //   notificacoes: data
        // }))
        
      } catch (error) {
        console.log(error)
      }
    };

    fetchData();    
  }, []);
 
  const pessoa = JSON.parse(localStorage.getItem('pessoa'))

  const {notificacoes, menuFiltrado, openNotification, ultimoCaixa, velocidadeInternet, representantesAbertos, unidades_acesso, dadosUsuario,
    unidades, acesso_atual } = state

  return (
    <div>
      {window._env_.REACT_APP_APLICACAO === "homologacao" &&
        <div>
          <div className="boxHomologacaoFundo">
            {/* <i className="fas fa-exclamation-triangle"></i> */}
            <p>Você está em um ambiente de Homologação</p>
          </div>
        </div>
      }
      <div className={`page-wrapper chiller-theme ${state.menu_open ? 'toggled' : ''} `}>
        <div data-cy="open-menu" id="show-sidebar" className="btn btn-sm btn-dark" onClick={() => openMenu()}>
          <i className="fa fa-bars"></i>
        </div>
      </div>

      <nav id="sidebar" className={`sidebar-wrapper ${state.menu_open ? 'sidebar-wrapper-open' : 'sidebar-wrapper-close'}`}>
        <div data-cy="menu" className="sidebar-content">
          <div className="sidebar-brand">
            <span style={{fontSize:'14px', textTransform: 'uppercase', fontWeight: 'bold', flexGrow: 1, color: '#FFF'}}>Mais Financeiro</span>
            <div data-cy="close-menu" onClick={() => closeMenu()} id="close-sidebar">
              <i className="fa fa-times"></i>
            </div>
          </div>
          <div className="sidebar-header">
            <div data-cy="user-info" className="user-info">
              <span className="user-name">{pessoa.fisica.nome} <span className="user-role"> - {pessoa.fisica.funcionario.contrato.funcao.descricao}</span></span>
              {ultimoCaixa && !ultimoCaixa.fechado &&
                <div className="caixa">
                  <Link to={`/caixa/view/${ultimoCaixa.id}`}>
                    <p>Caixa Nº {ultimoCaixa.numero}</p>
                  </Link>
                </div>
              }
            </div>
          </div>
          <div className="sidebar-menu">
            <ul data-cy="lista-menu" style={{marginTop: '10px'}}>
              {menuFiltrado.map((item, key) => {
                if(item.subMenu.length === 0){
                  return (
                    <li key={key}>
                      <Link to={item.href}>
                        <i className={item.icone}></i>
                        <span>{item.title}</span>
                      </Link>
                    </li>
                  )
                }else{
                  return (
                    <li key={key} className={`sidebar-dropdown ${item.subMenuOpen ? 'active' : ''}`}>
                      <a onClick={() => dropdown(item.index)}>
                        <i className={item.icone}></i>
                        <span>{item.title}</span>
                      </a>
                      <div className={`sidebar-submenu ${item.subMenuOpen ? 'open' : 'close'}`}>
                        <ul>
                          {item.subMenu.map((itemSub, keySub) => {
                            if(itemSub.subMenu.length === 0){
                              return (
                                <li key={keySub}>
                                  <Link to={itemSub.href}>
                                    <span>{itemSub.title}</span>
                                  </Link>
                                </li>
                              )
                            }else{
                              return (
                                <div></div>
                              )
                            }
                          })}
                        </ul>
                      </div>
                    </li>
                  )
                }                
              })}
              <li>
                <Link to={'/faqs'}>
                  <i className='fa fa-question'></i>
                  <span>Perguntas Frequentes</span>
                </Link>
              </li>
            </ul>
          </div>
          <div className="sidebar-footer">
            <a data-cy="button-logout" onClick={e => logout()}>
              <i className="fa fa-power-off"></i>
            </a>
          </div>
        </div>
      </nav>

      {velocidadeInternet >= 30 &&
        <Tooltip title={`Conexão: Ótimo`} aria-label="add">
          <div id='conexao' className='wifi'>
            <img className='icone_wifi' src={excellent} alt="Icone Wifi" />
          </div>
        </Tooltip>  
      }

      {(velocidadeInternet < 30 && velocidadeInternet >= 15) &&
        <Tooltip id='conexao' title={`Conexão: Regular`} aria-label="add">
          <div className='wifi'>
            <img className='icone_wifi' src={regular} alt="Icone Wifi" />
          </div>
        </Tooltip>
      }

      {velocidadeInternet < 15 &&
        <Tooltip id='conexao' title={`Conexão: Ruim`} aria-label="add">
          <div className='wifi'>
            <img className='icone_wifi' src={bad} alt="Icone Wifi" />
          </div>
        </Tooltip>
      }

      {(!openNotification && !state.openSwap) &&
        <div>
          {notificacoes.length === 0 &&
            <div id='icone-notificacao' data-cy="button-notification" className='notificacao'>
              <Tooltip title='Notificações' aria-label="add">
                <IconButton aria-label="view" onClick={() => openSwapNotification()}>
                  <NotificationsIcon style={{color: '#FFA500'}}/>
                </IconButton>
              </Tooltip>
            </div>
          }

          {notificacoes.length > 0 &&
            <div>
              <div data-cy="button-notification" className='notificacao'>
                <Tooltip title='Notificações' aria-label="add">
                  <IconButton aria-label="view" onClick={() => openSwapNotification()}>
                    <ShakingIcon style={{color: '#FFA500'}}/>
                  </IconButton>
                </Tooltip>
              </div>
              <div className='num_notificacao'>
                <span>{notificacoes.length}</span>
              </div>
            </div>
          }
        </div>
      }

      <div id='unidade-selecionada' className={`unidadeSelecionada ${(state.openSwap || openNotification) ? 'open' : 'closed'}`}>
        {renderTituloAcesso()}
      </div>

      {openNotification &&
        <ClickAwayListener onClickAway={e => handleClickAwayNotification(e)}>
          <div className='card_swap_open'>
            <div data-cy="menu-notification" style={{width: '100%', overflow: 'auto', height: '100%', paddingBottom: '20px'}}>
              <div className='center'>
                <h4 style={{marginLeft: '10px', marginTop: '5px'}} className="titulo">NOTIFICAÇÕES</h4>
              </div>
              <i data-cy="close-notification" style={{marginTop: '-5px'}} className="fa fa-times icone_close" onClick={e => openSwapNotification()}></i>
              {notificacoes.map(notificacao => {
                return (
                  <Link to={notificacao.url}>
                    <div className='itemNotificacao' style={{ backgroundColor: notificacao.cor }}>
                      <p style={{ color: notificacao.corFonte }}>{notificacao.descricao}</p>
                    </div>
                  </Link>
                )
              })}
            </div>
          </div>
        </ClickAwayListener>
      }

      {(!state.openSwap && !openNotification) &&
        <Tooltip title='Trocar Unidade' aria-label="add">
          <div id='troca-unidade' data-cy="button-swap-unidade" className='card_swap_closed' onClick={e => openSwap()}>
            <i className="fa fa-exchange"></i>
          </div>
        </Tooltip>
      }

      {state.openSwap &&
        <ClickAwayListener onClickAway={e => handleClickAway(e)}>
          <div data-cy="menu-unidades" className='card_swap_open'>
            <div className='topo'>
              <div className='center'>
                <h4 className="titulo">UNIDADES</h4>
              </div>
              <i data-cy="close-swap" className="fa fa-times" onClick={e => openSwap()}></i>
              {/* <Grid container direction="row" spacing={1} className="mg_top_10">
                <Grid item md={1} xs={1} sm={1}></Grid>
                <Grid item md={10} xs={10} sm={10}>
                  <TextField className="input" variant="outlined" size="small" name="descricao" value='' placeholder='Buscar Unidade'/>
                </Grid>
                <Grid item md={1} xs={1} sm={1}></Grid>
              </Grid> */}
            </div>
            <div className='meio'>
              {state.dadosUsuario.fisica.funcionario.contrato.unidadetrabalho.tipo === 'MANTENEDOR' &&
                <div>
                  <div className='renderUnidades' onClick={e => updateFieldCheckbox(unidades_acesso.franqueador)}>
                    {acesso_atual.findIndex(acesso => acesso.id === unidades_acesso.franqueador.id) === -1 &&
                      <RadioButtonUncheckedIcon/>
                    }
                    {acesso_atual.findIndex(acesso => acesso.id === unidades_acesso.franqueador.id) !== -1 &&
                      <CheckCircleIcon/>
                    }
                    <span>{unidades_acesso.franqueador.numero ? `${unidades_acesso.franqueador.descricao}` : unidades_acesso.franqueador.descricao} {unidades_acesso.franqueador.status ? '' : ' - Inativo'}</span>
                  </div>
                  <hr className="mg_top_10"/>
                  <div>
                    {unidades_acesso.franqueador.representantes.sort((a, b) => (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0)).map(representante => {
                      return (
                        <div key={representante.id}>
                          <div className={representantesAbertos.findIndex(param => param === representante.id) === -1 ? "franquiasFechada" : "franquias"}>
                            {representantesAbertos.findIndex(param => param === representante.id) === -1 &&
                              <ExpandMoreIcon className='iconeMaximize' onClick={() => openRepresentante(representante.id)}/>
                            }
                            {representantesAbertos.findIndex(param => param === representante.id) !== -1 &&
                              <ExpandLessIcon className='iconeMinimize' onClick={() => openRepresentante(representante.id)}/>
                            }
                            <div className='renderUnidades representante' onClick={e => updateFieldCheckbox(representante)}>
                              {acesso_atual.findIndex(acesso => acesso.id === representante.id) === -1 &&
                                <RadioButtonUncheckedIcon/>
                              }
                              {acesso_atual.findIndex(acesso => acesso.id === representante.id) !== -1 &&
                                <CheckCircleIcon/>
                              }
                              <span>{representante.numero ? `${representante.descricao}` : representante.descricao} {representante.status ? '' : ' - Inativo'}</span>
                            </div>
                            <div>
                              {representante.franquias.sort((a, b) => (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0)).map((franquia, key) => {
                                return (
                                  <div key={key} className="franquias">
                                    {franquia.exibe_menu &&
                                      <div className='renderUnidades' onClick={e => updateFieldCheckbox(franquia)}>
                                        {acesso_atual.findIndex(acesso => acesso.id === franquia.id) === -1 &&
                                          <RadioButtonUncheckedIcon/>
                                        }
                                        {acesso_atual.findIndex(acesso => acesso.id === franquia.id) !== -1 &&
                                          <CheckCircleIcon/>
                                        }
                                        <span>{franquia.numero ? `${franquia.descricao}` : franquia.descricao} {franquia.status ? '' : ' - Inativo'}</span>
                                      </div>
                                    }
                                    <div className="unidades">
                                      {franquia.unidades.sort((a, b) => (a.numero > b.numero) ? 1 : ((b.numero > a.numero) ? -1 : 0)).map((unidade, key) => {
                                        return (
                                          <div key={key} className='renderUnidades' onClick={e => updateFieldCheckbox(unidade)}>
                                            {acesso_atual.findIndex(acesso => acesso.id === unidade.id) === -1 &&
                                              <RadioButtonUncheckedIcon/>
                                            }
                                            {acesso_atual.findIndex(acesso => acesso.id === unidade.id) !== -1 &&
                                              <CheckCircleIcon/>
                                            }
                                            <span>{unidade.numero ? `${unidade.descricao}` : unidade.descricao} {unidade.status ? '' : ' - Inativo'}</span>
                                          </div>
                                        )
                                      })}
                                    </div>
                                  </div>
                                )
                              })}
                            </div>
                          </div>
                          <hr className="mg_top_10"/>
                        </div>
                      )
                    })}
                  </div>
                </div>
              }
              {state.dadosUsuario.fisica.funcionario.contrato.unidadetrabalho.tipo === 'REPRESENTANTE' &&
                <div>
                  <div className='renderUnidades' onClick={e => updateFieldCheckbox(unidades_acesso.representante)}>
                    {acesso_atual.findIndex(acesso => acesso.id === unidades_acesso.representante.id) === -1 &&
                      <RadioButtonUncheckedIcon/>
                    }
                    {acesso_atual.findIndex(acesso => acesso.id === unidades_acesso.representante.id) !== -1 &&
                      <CheckCircleIcon/>
                    }
                    <span>{unidades_acesso.representante.numero ? `${unidades_acesso.representante.descricao}` : unidades_acesso.representante.descricao} {unidades_acesso.representante.status ? '' : ' - Inativo'}</span>
                  </div>
                  <div>
                    {unidades_acesso.representante.franquias.sort((a, b) => (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0)).map((franquia, key) => {
                      return (
                        <div key={key} className="representante">
                          <div className='renderUnidades franquias' onClick={e => updateFieldCheckbox(franquia)}>
                            {acesso_atual.findIndex(acesso => acesso.id === franquia.id) === -1 &&
                              <RadioButtonUncheckedIcon/>
                            }
                            {acesso_atual.findIndex(acesso => acesso.id === franquia.id) !== -1 &&
                              <CheckCircleIcon/>
                            }
                            <span>{franquia.numero ? `${franquia.descricao}` : franquia.descricao} {franquia.status ? '' : ' - Inativo'}</span>
                          </div>
                          <div className="unidades" style={{marginLeft: 40}}>
                            {franquia.unidades.sort((a, b) => (a.numero > b.numero) ? 1 : ((b.numero > a.numero) ? -1 : 0)).map((unidade, key) => {
                              return (
                                <div key={key} className='renderUnidades' onClick={e => updateFieldCheckbox(unidade)}>
                                  {acesso_atual.findIndex(acesso => acesso.id === unidade.id) === -1 &&
                                    <RadioButtonUncheckedIcon/>
                                  }
                                  {acesso_atual.findIndex(acesso => acesso.id === unidade.id) !== -1 &&
                                    <CheckCircleIcon/>
                                  }
                                  <span>{unidade.numero ? `${unidade.descricao}` : unidade.descricao} {unidade.status ? '' : ' - Inativo'}</span>
                                </div>
                              )
                            })}
                          </div>
                          <hr className="mg_top_10"/>
                        </div>
                      )
                    })}
                  </div>
                </div>
              }
              {state.dadosUsuario.fisica.funcionario.contrato.unidadetrabalho.tipo === 'GRUPOECONOMICO' &&
                <div>
                  <div className='renderUnidades' onClick={e => updateFieldCheckbox(unidades_acesso.franquia)}>
                    {acesso_atual.findIndex(acesso => acesso.id === unidades_acesso.franquia.id) === -1 &&
                      <RadioButtonUncheckedIcon/>
                    }
                    {acesso_atual.findIndex(acesso => acesso.id === unidades_acesso.franquia.id) !== -1 &&
                      <CheckCircleIcon/>
                    }
                    <span>{unidades_acesso.franquia.numero ? `${unidades_acesso.franquia.descricao}` : unidades_acesso.franquia.descricao} {unidades_acesso.franquia.status ? '' : ' - Inativo'}</span>
                  </div>
                  <div className="unidades" style={{marginLeft: 40}}>
                    {unidades_acesso.franquia.unidades.sort((a, b) => (a.numero > b.numero) ? 1 : ((b.numero > a.numero) ? -1 : 0)).map((unidade, key) => {
                      return (
                        <div key={key} className='renderUnidades' onClick={e => updateFieldCheckbox(unidade)}>
                          {acesso_atual.findIndex(acesso => acesso.id === unidade.id) === -1 &&
                            <RadioButtonUncheckedIcon/>
                          }
                          {acesso_atual.findIndex(acesso => acesso.id === unidade.id) !== -1 &&
                            <CheckCircleIcon/>
                          }
                          <span>{unidade.numero ? `${unidade.descricao}` : unidade.descricao} {unidade.status ? '' : ' - Inativo'}</span>
                        </div>
                      )
                    })}
                  </div>
                  <hr className="mg_top_10"/>
                </div>
              }
              {state.dadosUsuario.fisica.funcionario.contrato.unidadetrabalho.tipo === 'UNIDADE' &&
                <div className='renderUnidades' onClick={e => updateFieldCheckbox(unidades_acesso.unidade)}>
                  {acesso_atual.findIndex(acesso => acesso.id === unidades_acesso.unidade.id) === -1 &&
                    <RadioButtonUncheckedIcon/>
                  }
                  {acesso_atual.findIndex(acesso => acesso.id === unidades_acesso.unidade.id) !== -1 &&
                    <CheckCircleIcon/>
                  }
                  <span>{unidades_acesso.unidade.numero ? `${unidades_acesso.unidade.descricao}` : unidades_acesso.unidade.descricao} {unidades_acesso.unidade.status ? '' : ' - Inativo'}</span>
                </div>
              }
            </div>
            <div className='base'>
              <Grid style={{position: 'absolute', bottom: '0px'}} container direction="row" spacing={1} className="mg_top_10">
                <Grid item md={12} xs={12} sm={12}>
                <Button data-cy="button-swap" fullWidth color="primary" variant="contained" size="small" startIcon={<SyncAltIcon />} onClick={e => trocarAcesso()}>Trocar Acesso</Button>
                </Grid>
              </Grid>
            </div>
          </div>
        </ClickAwayListener>
      }
    <Alert 
      open={state.alerta.open}
      handleClose={e => handleCloseAlerta()} 
      severity={state.alerta.severity}
      message={state.alerta.message} />        
    </div>
  )
}

export default Nav
