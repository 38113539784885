import React, { useState, useEffect } from 'react';
import './AlteracaoCadastro.scss'
import './../../main/ultil.scss'
import { Link } from 'react-router-dom'
import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import Table from '../../components/TableV2'
import axios from 'axios'
import SearchIcon from '@mui/icons-material/Search';
import { Grid, Button, TextField } from '@mui/material'
import Loading from '../../components/templates/Loading'
import Alert from '../../components/templates/Alert'
import { saveAs } from 'file-saver';
import qs from 'qs'
import ModalAjuda from '../../components/modals/Ajuda'
import { useNavigate } from 'react-router-dom';
import initialState from './initialState/alteracaoCadastro';
import formatarTelefone from '../../services/formatarTelefone'
import getToken from '../../services/getToken'
import moment from 'moment';

const Cliente = () => {
  const [state, setState] = useState({ ...initialState });
  const navigate = useNavigate();

  const updateFiltro = (event) => {
    const {filtro, filtrocliente} = state
    
    if (event.target.name === 'filtrar_por') {    
      setState(prevState => ({...prevState,
        filtrocliente: {
          label: event.target.value.split(' - ')[0],
          name: event.target.value.split(' - ')[1]
        }
      }))

      filtro[event.target.name] = event.target.value
      filtro.nome = ''
      filtro.cpf_cnpj = ''
      filtro.telefone = ''

      setState(prevState => ({...prevState,
        filtro
      }))
    }else if (event.target.name === 'estado' || event.target.name === 'ativo'){
      filtro[event.target.name] = event.target.value
      
      setState(prevState => ({...prevState,
        filtro
      }))
    }else{
      filtro[filtrocliente.name] = event.target.value
      filtro[event.target.name] = event.target.value
      
      setState(prevState => ({...prevState,
        filtro
      }))
    }
  }

  const filtrar = async () => {
    const {filtro} = state
    filtro.limit = 10
    filtro.offset = 0
    setState(prevState => ({...prevState,filtro}))

    criaUrl(filtro)
    localStorage.setItem('filtro_solicitacao_alteracao_cadastro', JSON.stringify(filtro));

    await filtrarCliente(filtro)
  }

  const criaUrl = (filtro) => {
    let url = `/solicitacao_alteracao_cadastro?limit=${filtro.limit}&offset=${filtro.offset}`

    if(filtro.filtrar_por !== ''){
      url += `&filtrar_por=${filtro.filtrar_por.replaceAll(' ', '%')}&value=${filtro.value.replaceAll(' ', '%')}`
    }

    if(filtro.nome !== ''){
      url += `&nome=${filtro.nome.replaceAll(' ', '%')}`
    }

    if(filtro.cpf_cnpj !== ''){
      url += `&cpf_cnpj=${filtro.cpf_cnpj.replaceAll(' ', '')}`
    }

    if(filtro.telefone !== ''){
      url += `&telefone=${filtro.telefone.replaceAll(' ', '')}`
    }

    navigate(url);
  }

  const formatarStatus = (status) => {
    if(status === "AGUARDANDO_APROVACAO")
      return "Aguardando Aprovação"
    if(status === "APROVADO")
      return "Aprovado"
    if(status === "REPROVADO")
      return "Reprovado"
  }

  const filtrarCliente = async (filtro) => {
    try {
      setState(prevState => ({...prevState,
        loading: true
      }))

      const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))

      const dados = {
        unidadesnegocio: acesso_atual.map(acesso => {
          return acesso.id
        })
      }

      filtro.unidadesnegocio = dados.unidadesnegocio

      let dadosFiltro = {
        unidadesnegocio: dados.unidadesnegocio,
        limit: filtro.limit,
        offset: filtro.offset * filtro.limit,
        nome: filtro.nome,
        cpf_cnpj: filtro.cpf_cnpj,
        telefone: filtro.telefone,
        estado: filtro.estado
      }
      
      localStorage.setItem('filtro_pessoas', JSON.stringify(filtro));

      const { data } = await axios.post(`${window._env_.REACT_APP_API_URL}/pessoa/listSolicitacaoAlteracao`, dadosFiltro, getToken())
      
      console.log(data)

      const pessoas = []

      data.list.forEach(pessoa => {
        pessoas.push({
          _id: pessoa.id,
          tipo: pessoa.tipo,
          nome: pessoa.tipo === 'FISICA' ? `${pessoa.nome} ${pessoa.sobrenome ? pessoa.sobrenome : ''}` : pessoa.nome,
          sobrenome: pessoa.sobrenome,
          cpf_cnpj: pessoa.cpf_cnpj,
          numero: formatarTelefone(pessoa.numero),
          estado_contrato: formatarStatus(pessoa.status),
          email: pessoa.email,
          criado_em: moment(pessoa.criado_em).format('DD/MM/YYYY'),
          criado_em_order: moment(pessoa.criado_em).format('YYYYMMDD')
        })
      })
      
      setState(prevState => ({...prevState,
        pessoas: {
          list: pessoas.sort((a, b) => (a.criado_em_order > b.criado_em_order) ? 1 : ((b.criado_em_order > a.criado_em_order) ? -1 : 0))
        },
        pessoasFiltrado: {
          list: pessoas
        },
        rows: data.rows,
        loading: false
      }))

    } catch (error) {
      console.log(error)
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      }))
    }
  }

  const handleCloseAlerta = () => {
    setState(prevState => ({...prevState,
      alerta: {
        open: false,
        autoHideDuration: 5000,
        severity: state.alerta.severity,
        message: ''
      }
    }))
  }

  const handleChangePage = async (event, newPage) => {
    const {filtro} = state
    filtro.offset = newPage
    setState(prevState => ({...prevState,
      filtro
    }))

    await filtrarCliente(filtro)
  }

  const handleChangeRowsPerPage = async (event) => {
    const {filtro} = state
    filtro.limit = parseInt(event.target.value)
    setState(prevState => ({...prevState,
      filtro
    }))

    await filtrarCliente(filtro)
  }

  useEffect(() => {
    const fetchData = async () => {
      const perfil = JSON.parse(localStorage.getItem('perfil'))
      let permissoes = perfil.permissoes.filter(param => param.tela.modulo.slug === 'pre-cadastro-cliente')[0]

      let acoesTabela = []

      if (permissoes.visualizar) {
        acoesTabela.push('view')
      }

      setState(prevState => ({...prevState,
        acoesTabela,
        permissoes
      }))

      await filtrarCliente(state.filtro)
      
      setState(prevState => ({...prevState,
        loading: false
      }))
    };
    
    fetchData();
  }, []);

  const { filtro, pessoasFiltrado, permissoes, rows } = state
  const pessoa = JSON.parse(localStorage.getItem('pessoa'))
  const tipo = pessoa ? pessoa.fisica.funcionario.contrato.unidadetrabalho.tipo : 'UNIDADE'

  return (
    <React.Fragment>
      {!state.loading &&
        <div className="app-menu-closed" id="app">
          <Main openModalAjuda={() => {setState(prevState => ({...prevState,openModalAjuda: true}))}}>
            <div>
              <Grid container spacing={1} direction="row" className="borderBottom">
                <Grid item md={12} xs={12} sm={12}>
                  <h1 className="titulo">Solicitação de Alteração de Cadastro do Clientes</h1>
                </Grid>
              </Grid>
              <Grid container direction="row" spacing={1} className="mg_top_20">
                <Grid item md={2} xs={12} sm={6}>
                  <TextField
                    id="standard-select-currency"
                    select
                    label="Filtrar por"
                    variant="outlined"
                    // className="input"
                    fullWidth
                    size="small"
                    SelectProps={{
                      native: true,
                    }}
                    name="filtrar_por"
                    value={filtro.filtrar_por}
                    onChangeCapture={(e) => updateFiltro(e)}
                    InputLabelProps={{ shrink: true }}
                  >
                    <option value="Nome - nome"> Nome / Nome Fantasia </option>
                    <option value="CPF - cpf_cnpj"> CPF / CNPJ </option>
                    <option value="Telefone - telefone"> Telefone </option>
                  </TextField>
                </Grid>
                <Grid item md={3} xs={12} sm={6}>
                  <TextField className="input" label={state.filtrocliente.label} variant="outlined" size="small" name='value' value={filtro.value} onChange={(e) => updateFiltro(e)} InputLabelProps={{ shrink: true }} placeholder='Pesquisar' />
                </Grid>
                <Grid item md={2} xs={12} sm={6}>
                  <TextField
                    id="standard-select-currency"
                    select
                    label="Situação"
                    variant="outlined"
                    className="input"
                    fullWidth
                    size="small"
                    SelectProps={{
                      native: true,
                    }}
                    name="estado"
                    value={filtro.estado}
                    onChangeCapture={(e) => updateFiltro(e)}
                    InputLabelProps={{ shrink: true }}
                  >
                    <option value=""> </option>
                    <option value="AGUARDANDO_APROVACAO">Aguardando Aprovação</option>
                    <option value="APROVADO">Aprovado</option>
                    <option value="REPROVADO">Reprovado</option>
                  </TextField>
                </Grid>
                <Grid item md={1} xs={12} sm={1}>
                  <Button color='warning' className='btn_search_func' fullWidth variant="contained" endIcon={<SearchIcon />} onClick={e => filtrar(e)}></Button>
                </Grid>
              </Grid>
              <Grid container spacing={1} direction="row" className="mg_top_10">
                <Grid className='ocultar-mobile' item md={12} xs={12}>
                  <Table 
                    urlView="/solicitacao_alteracao_cadastro/view/" 
                    headCell={state.cabecalhoTabela} 
                    rows={pessoasFiltrado.list} 
                    acoes={state.acoesTabela}
                    filtro={filtro}
                    count={rows}
                    handleChangePage={(event, newPage) => handleChangePage(event, newPage)}
                    handleChangeRowsPerPage={e => handleChangeRowsPerPage(e)}
                    tipo_unidade={tipo}
                  />
                </Grid>

                <Grid className='ocultar-desktop' item md={12} xs={12}>
                  <Table 
                    urlView="/solicitacao_alteracao_cadastro/view/" 
                    headCell={state.cabecalhoTabelaMobile} 
                    rows={pessoasFiltrado.list} 
                    acoes={state.acoesTabela}
                    filtro={filtro}
                    count={rows}
                    handleChangePage={(event, newPage) => handleChangePage(event, newPage)}
                    handleChangeRowsPerPage={e => handleChangeRowsPerPage(e)}
                  />
                </Grid>
              </Grid>
            </div>
          </Main>
          <Nav/>
          <Alert 
            open={state.alerta.open}
            handleClose={e => handleCloseAlerta()} 
            severity={state.alerta.severity}
            message={state.alerta.message} 
          />
        </div>
      }
      <Loading 
        open={state.loading}
        message={state.mensagemLoading} 
      />
      {/* <ModalAjuda 
        open={state.openModalAjuda}
        tela={"Listagem de Pré Cadastro"}
        handleClose={() => {setState(prevState => ({...prevState,openModalAjuda: false}))}}
        markdown={state.markdown}
      /> */}
    </React.Fragment>
  )
}

export default Cliente